import React, { useState, useEffect } from "react";
import LayoutNo from "../utils/LayoutNo";

import BrushIcon from "../images/brush.svg";
import MetierCard from "../utils/MetierCard";
import { useMetierService } from "../services/metier.service";
import ArrowDown from "../images/arrowDown";
import { useUserService } from "../services/userforms.service";
import LoginModal from "../components/modal/auth/LoginModal";
import { useItemsService } from "../services/ items.service";
import {useRef} from "react";
import { CryptoService } from "../services/crypte.service";
import NodataFound from "../utils/NoDataFound";
import { notification, Pagination, Skeleton, Switch} from 'antd';
import {useAuth} from "../services/auth.provider";
import MetiersFilters from "../components/MetiersFilters";
import {Helmet} from "react-helmet";


export default function SearchSystemmetier() {
    const [metiers, setMetiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingpub, setLoadingPub] = useState(true);
    const [isSalaireOpen, setIsSalaireOpen] = useState(false);
    const [salaryRanges, setSalaryRanges] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedStudyLevel, setSelectedStudyLevel] = useState([]);
    const [selectedBusinessSector, setSelectedBusinessSector] = useState([]);
    const [seniorPosition, setSeniorPosition] = useState(0);
    const [selectedSalaryRange, setSelectedSalaryRange] = useState([]);
    const [searchKeywords, setSearchKeywords] = useState('');
    const [userId, setUserId] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [keywords, setKeywords] = useState('');
    const toast = useRef(null);
    const [clearFilters, setClearFilters] = useState(false);
    const {  getSalaireRange } = useUserService();
    const { searchProfessions,getProfessionsPublic } = useMetierService();
    const { saveProfession, deleteProfession } = useItemsService();
    const { isAuthenticated } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [dataFetched, setDataFetched] = useState(false);
    const [professionData, setProfessionData] = useState([]);
    const [filtersChanged, setFiltersChanged] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            if (user) {
                const decryptedId = CryptoService.decryptData(user);
                setUserId(decryptedId);
                fetchProfessions();
            }
        }
    }, [isAuthenticated]);


    useEffect(() => {
        const fetchData = async () => {
            setLoadingPub(true);
            const response = await getProfessionsPublic();
            if (response.success) {
                setProfessionData(response.data);
            } else {
                console.error(response.message);
            }
            setLoadingPub(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("formMetiersSearchData"));
        if (savedData) {
            setSelectedStudyLevel(savedData.selectedStudyLevel || '');
            setSelectedBusinessSector(savedData.selectedBusinessSector || '');
            setSeniorPosition(Number(savedData.seniorPosition) || 0);
            setSelectedSalaryRange(savedData.selectedSalaryRange || '');
            setSearchKeywords(savedData.searchKeywords || '');
            setSelectedOption(savedData.selectedOption || '');
        }
    }, []);

    useEffect(() => {
        const userMetiersSearchData = {
            searchKeywords,
            selectedOption,
            selectedSalaryRange,
            seniorPosition,
            selectedBusinessSector,
            selectedStudyLevel,
        };
        localStorage.setItem("formMetiersSearchData", JSON.stringify(userMetiersSearchData));
    }, [selectedStudyLevel, selectedBusinessSector, seniorPosition, selectedSalaryRange, searchKeywords, selectedOption]);


    useEffect(() => {
        const fetchSalaryRanges = async () => {
            try {
                const response = await getSalaireRange();
                setSalaryRanges(response.data.data);
            } catch (error) {
                console.error("Error fetching salary ranges:", error);
            }
        };
        fetchSalaryRanges();
    }, []);

    const fetchProfessions = async (page = currentPage) => {
        if (!userId) return;
        setLoading(true);
        setDataFetched(false);
        const studLevelValue =  (selectedStudyLevel && selectedStudyLevel.length > 0) ? selectedStudyLevel : undefined;
        const businessSector = (selectedBusinessSector && selectedBusinessSector.length > 0) ? selectedBusinessSector : undefined;
        const salaryRange = (selectedSalaryRange && selectedSalaryRange.length > 0) ? selectedSalaryRange : undefined;

        try {
            const response = await searchProfessions(
                studLevelValue,
                businessSector,
                seniorPosition,
                salaryRange,
                searchKeywords,
                null,
                userId,
                page
            );

            if (response.success) {
                setMetiers(response.data.results || []);
                setTotalCount(response.data.total_count || 0);
            } else {
                setMetiers([]);
            }
        } catch (error) {
            console.error("Error fetching professions:", error);
            setMetiers([]);
        } finally {
            setLoading(false);
            setDataFetched(true);
        }
    };

    // useEffect(() => {
    //     if (userId) {
    //         fetchProfessions();
    //     }
    // }, [userId,currentPage,clearFilters]);

    useEffect(() => {
        fetchProfessions(currentPage);
    }, [
        selectedStudyLevel,
        selectedBusinessSector,
        seniorPosition,
        selectedSalaryRange,
        searchKeywords,
        currentPage
    ]);

    const onPageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo(0, 0);

    };


    // const handleOptionClick = (option) => {
    //     setSelectedSalaryRange(option.salary_range_id);
    //     setSelectedOption(option.salary_range);
    //     setIsSalaireOpen(false);
    // };
    const handleCheckboxClick = (option) => {
        if (selectedSalaryRange.includes(option.salary_range_id)) {
            // Remove from selection
            setSelectedSalaryRange(selectedSalaryRange.filter(id => id !== option.salary_range_id));
            setSelectedOption(option.salary_range);

        } else {
            // Add to selection
            setSelectedSalaryRange([...selectedSalaryRange, option.salary_range_id]);
        }
    };

    const handleClearAll = () => {
        setSelectedStudyLevel('');
        setSelectedBusinessSector('');
        setSelectedSalaryRange([]);
        setSeniorPosition(0);
        setMetiers([]);
        setSearchKeywords('');
        setKeywords('');
        setSelectedOption('');
        setClearFilters(true);
        setCurrentPage('1');
        setTimeout(() => {
            setClearFilters(false);
        }, 0);
        // localStorage.removeItem("formMetiersSearchData");




    };


    const handleMissingStepsNotification = () => {
        notification.warning({
            message: 'Veuillez sélectionner trois critères ou utilisez des mots-clés pour effectuer une recherche.',
            placement: 'topRight',
        });
    };



    const handleSearch = () => {
        if (!isAuthenticated) {
            showModal();
            return;
        }

        if (keywords) {
            setSearchKeywords(keywords);
        } else {
            handleMissingStepsNotification();
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };


    const toggleSalaireDropdown = () => setIsSalaireOpen(!isSalaireOpen);

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const handleSaveProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await saveProfession(userId, professionId);
            notification.success({
                message: 'Enregistré',
                placement: 'topRight',
            });
        } catch (error) {
            console.error('Error saving profession:', error);
            notification.error({
                message: 'Erreur',
                description: 'Une erreur est survenue lors de la sauvegarde de la profession.',
                placement: 'topRight',
            });
        }
    };

    const handleDeleteProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await deleteProfession(userId, professionId);
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };
    const handleSeniorPositionToggle = () => {
        setSeniorPosition(prev => (prev === 0 ? 1 : 0));
    };

    const handleStudyLevelChange = (selectedOptions) => {
        setSelectedStudyLevel(selectedOptions.map(option => option.studlevel_id));
        setFiltersChanged(true);
        setCurrentPage(1);
    };

    const handleBusinessSectorChange = (selectedOptions) => {
        setSelectedBusinessSector(selectedOptions.map(option => option.business_sect_id));
        setFiltersChanged(true);
        setCurrentPage(1);
    };


    return (
        <LayoutNo>
            <Helmet>
                <title>Linkyways</title>
            </Helmet>
            <div className={`mt-6  md:mt-2 w-full p-1 `}>

                <div>
                    {/*<video  autoPlay loop muted playsInline poster={Poster}  className="absolute top-0 left-0 w-full h-full object-cover rounded-xl">*/}
                    {/*    <source  src="https://linkystock.blob.core.windows.net/stellar/linkyverse.mp4" type="video/mp4" />*/}
                    {/*</video>*/}
                    {/*<div className="absolute inset-0   items-center justify-center text-white text-lg font-bold">*/}
                    {/*    <CarouselSearchFormation type={"Près de 2000 métiers dans 40 secteurs d'activité"} description={"Trouvez celui qui vous correspond."} highlight={""}/>*/}
                    {/*</div>*/}


                    <div className="  md:mt-2 mt-2 inset-0 flex items-center justify-center px-1 ">

                        <div className="bg-white w-full  mx-3 md:mx-0  border-2  rounded-lg shadow-md flex items-center space-x-2 p-1">
                            <div className="flex-grow">
                                <span className="absolute left-18 md:top-[92px] top-[84px] transform -translate-y-1/2 text-gray-500 ml-1">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.332 12.333L15.7493 15.7502" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                                <input
                                    type="text"
                                    value={keywords}
                                    placeholder={searchKeywords || "Rechercher un métier, une compétence..." }
                                    onKeyUp={handleKeyPress}
                                    onChange={(e) => setKeywords(e.target.value)}
                                    className="w-full p-1 text-sm text-gray-700 rounded-l focus:outline-none ml-4"
                                    spellCheck={false}
                                    style={{ fontSize: "16px" }}

                                />
                            </div>

                            <button
                                type="button"
                                onClick={handleSearch}
                                className="hidden md:flex  justify-center items-center p-2  text-[16px] font-inter font-semibold  rounded-md border border-blue-500 bg-[#1681FF] text-white"
                            >
                                {/* Search Icon */}
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                                    <path d="M8.40307 14.0625C11.7636 14.0625 14.4878 11.4182 14.4878 8.15625C14.4878 4.89432 11.7636 2.25 8.40307 2.25C5.04258 2.25 2.31836 4.89432 2.31836 8.15625C2.31836 11.4182 5.04258 14.0625 8.40307 14.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.7051 12.333L16.2256 15.7502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                Recherche
                            </button>
                            <button
                                type="button"
                                onClick={handleSearch}
                                className="md:hidden   justify-center items-center p-2  text-[16px] font-inter font-semibold  rounded-md border border-blue-500 bg-[#1681FF] text-white"
                            >
                                {/* Search Icon */}
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M8.40307 14.0625C11.7636 14.0625 14.4878 11.4182 14.4878 8.15625C14.4878 4.89432 11.7636 2.25 8.40307 2.25C5.04258 2.25 2.31836 4.89432 2.31836 8.15625C2.31836 11.4182 5.04258 14.0625 8.40307 14.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.7051 12.333L16.2256 15.7502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>

                        </div>
                    </div>
                </div>



                <div className="p-1  ">
                    <div className="w-full flex flex-wrap lg:justify-between md:justify-center   items-center  md:px-0 px-3 ">
                        {/*{{ search for mobile screen}}*/}
                        {/*<div className="flex flex-wrap justify-start gap-2 w-full lg:w-auto items-center px-0 md:px-6 " onClick={!isAuthenticated ? showModal : null}>*/}
                        <div className="flex flex-wrap justify-start gap-2 w-full lg:w-auto items-center px-0 md:px-6 " >
                            {/* Study Level Select */}
                            <div className="w-full sm:w-1/2 lg:w-auto">
                                <MetiersFilters
                                    onSelectBusiessSector={handleBusinessSectorChange}
                                    clearFilters={clearFilters}
                                    onSelectStudLevel={handleStudyLevelChange}
                                    selectedStudLevelIds={selectedStudyLevel}
                                    selectedBusinessIds={selectedBusinessSector}
                                />
                            </div>

                            {/* Salaire Button */}
                            <div className="w-full sm:w-1/2 lg:w-auto relative">
                                <div className="relative lg:w-56">
                                    <button
                                        className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] shadow-md bg-white rounded-lg focus:outline-none"
                                        onClick={toggleSalaireDropdown}
                                    >
                                        <span>Salaire</span>
                                        <div className="flex items-center">
                                            {/* Black Dot with Count */}
                                            {selectedSalaryRange.length > 0 && (
                                                <span
                                                    className=" text-xs font-bold text-white bg-black rounded-full p-1  h-4 mr-1 flex items-center justify-center"
                                                >
                                                    {selectedSalaryRange.length}
                                                </span>
                                            )}
                                            {/* Arrow Down */}
                                            <span><ArrowDown /></span>
                                        </div>
                                    </button>

                                    {isSalaireOpen && (
                                        <div className="absolute left-0 mt-2 w-full bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-20">
                                            <div className="flex items-center justify-between px-2 py-3 border-b border-[#D0DBE7] bg-white rounded-t-md">
                                                <label className="text-black text-xs font-inter">Débutant</label>
                                                {/*<div*/}
                                                {/*    className={`relative border-1 border-[#235C9B] w-14 h-4 flex items-center rounded-full p-1 cursor-pointer transition-colors ${seniorPosition === 0 ? 'bg-[#E5EDF6]' : 'bg-gray-300'}`}*/}
                                                {/*    onClick={(e) => {*/}
                                                {/*        e.stopPropagation();*/}
                                                {/*        if (!isAuthenticated) {*/}
                                                {/*            showModal();*/}
                                                {/*            return;*/}
                                                {/*        }*/}
                                                {/*        handleSeniorPositionToggle();*/}
                                                {/*    }}*/}
                                                {/*>*/}
                                                {/*    <div*/}
                                                {/*        className={`bg-[#235C9B] w-4 h-3 rounded-full shadow-md transform transition-transform ${seniorPosition === 1 ? 'translate-x-8' : 'translate-x-0'}`}*/}
                                                {/*    ></div>*/}
                                                {/*</div>*/}
                                                <Switch
                                                    checked={seniorPosition === 1}
                                                    onChange={() => {
                                                        if (!isAuthenticated) {
                                                            showModal();
                                                            return;
                                                        }
                                                        handleSeniorPositionToggle();
                                                    }}
                                                    // checkedChildren="ON"
                                                    // unCheckedChildren="OFF"
                                                    className={` ${seniorPosition === 1 ? "bg-blue-500" : "bg-blue-100"}`}
                                                />
                                                <label className="text-black text-xs font-inter">Expérimenté</label>
                                            </div>
                                            <ul className="max-h-32 overflow-y-auto py-2">
                                                {salaryRanges.map((range) => (
                                                    <li
                                                        key={range.salary_range_id}
                                                        className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 ${
                                                            selectedSalaryRange.includes(range.salary_range_id) ? 'bg-blue-50' : ''
                                                        }`}
                                                        // onClick={() => handleCheckboxClick(range)}
                                                        onClick={() => {
                                                            if (!isAuthenticated) {
                                                                showModal(); // Show modal if not authenticated
                                                                return;
                                                            }
                                                            handleCheckboxClick(range);
                                                        }}
                                                    >
                                                        {selectedSalaryRange.includes(range.salary_range_id) ? (
                                                            <svg
                                                                width="16"
                                                                height="17"
                                                                viewBox="0 0 16 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="mr-2 min-w-4"
                                                            >
                                                                <rect y="0.5" width="16" height="16" rx="3" fill="#141943" />
                                                                <path
                                                                    d="M11.4375 6.625L6.85414 11L4.5625 8.8125"
                                                                    stroke="white"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                width="16"
                                                                height="17"
                                                                viewBox="0 0 16 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="mr-2 min-w-4"
                                                            >
                                                                <rect x="0.5" y="1" width="15" height="15" rx="2.5" stroke="#141943" />
                                                            </svg>
                                                        )}
                                                        <span className="text-sm text-gray-800">{range.salary_range}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Clear All Button */}
                        <div className="hidden md:flex justify-end  mt-2 lg:mt-0 mr-0 md:mr-6  ">
                            <button onClick={handleClearAll} className="flex gap-2 text-xs px-2 py-2 border-1 border-[#D0DBE7] rounded-md hover:bg-gray-300 ">
                                <img src={BrushIcon} alt="edit" className="w-6 h-6" />
                                <span className="whitespace-nowrap p-1">Tout effacer</span>
                            </button>
                        </div>
                    </div>
                    <div className="md:hidden flex justify-between  mt-2 lg:mt-0 mr-3   ">
                        <div></div>
                        <button onClick={handleClearAll} className="flex gap-2 border-1 rounded-md text-xs px-2 py-2  border-[#D0DBE7]  hover:bg-gray-300 ">
                            <img src={BrushIcon} alt="edit" className="w-6 h-6" />
                            <span className="whitespace-nowrap p-1">Tout effacer</span>
                        </button>
                    </div>

                    {loading && isAuthenticated  && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2  mt-5 md:px-2 px-4">
                            {Array.from({ length: 16 }).map((_, index) => (
                                <Skeleton active key={index} />
                            ))}
                        </div>
                    )}
                    {loadingpub && !isAuthenticated  && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2  mt-5 md:px-2 px-4 ">
                            {Array.from({ length: 16 }).map((_, index) => (
                                <Skeleton active key={index} />
                            ))}
                        </div>
                    )}
                    {!loading && dataFetched &&  metiers.length === 0 && (
                        (selectedStudyLevel || selectedBusinessSector || selectedSalaryRange || searchKeywords) ? (
                            <div className="mt-4">
                                <NodataFound clearFilter={handleClearAll} />
                            </div>
                        ) : null
                    )}
                    {!loading && dataFetched &&   metiers.length > 0 && (
                        <div className="mt-3 ">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2 md:px-2 px-4">
                                {metiers.map((metier) => (
                                    <MetierCard
                                        key={metier.profession_id}
                                        imgSrc={metier.pictact}
                                        smtitle={metier.business_sect}
                                        title={metier.profession}
                                        chips={[metier.required_stud_level]}
                                        frompriceDebutant={metier.salary_1}
                                        topriceDebutant={metier.salary_2}
                                        frompriceExp={metier.salary_3}
                                        topriceExp={metier.salary_4}
                                        professionId={metier.profession_id}
                                        saved={metier.saved}
                                        userId={userId}
                                        saveProfession={handleSaveProfession}
                                        deleteProfession={handleDeleteProfession}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {!loadingpub   && !isAuthenticated  && (
                        <div className="mt-3">
                            {/*<div className="w-full  flex justify-center px-2">*/}
                            {/*    <Alert message="Inscrivez-vous pour effectuer une recherche (c'est gratuit) !" type="info" className="mx-2 -mt-5 mb-2" showIcon                                     style={{ width: "100%", marginBottom: '16px' }}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2 md:px-2 px-4">
                                {professionData.map((metier) => (
                                    <MetierCard
                                        key={metier.profession_id}
                                        imgSrc={metier.pictact}
                                        userId={userId}
                                        smtitle={metier.business_sect}
                                        title={metier.profession}
                                        chips={[metier.required_stud_level]}
                                        frompriceDebutant={metier.salary_1}
                                        topriceDebutant={metier.salary_2}
                                        frompriceExp={metier.salary_3}
                                        topriceExp={metier.salary_4}
                                        professionId={metier.profession_id}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <LoginModal visible={isModalVisible} onHide={hideModal} />
            </div>
            <div className="flex justify-center my-2">
                {!loading && metiers.length !== 0  && (selectedStudyLevel || selectedBusinessSector || selectedSalaryRange || searchKeywords) && (
                    <Pagination
                        total={totalCount}
                        current={currentPage}
                        pageSize={16}
                        onChange={onPageChange}
                        showSizeChanger={false}
                    />

                )}

            </div>

        </LayoutNo>
    );
}
