import React, { useState } from 'react';
import { Modal, Button, Input, Typography, notification, Row, Col } from 'antd';
import { useAuthService } from '../../../services/auth.service';
import { RenewPasswordModal } from './RenewPasswordModal';

const { Title, Paragraph } = Typography;

export function OtpPasswordModal({ email, visible, onHide }) {
    const [otp, setOtp] = useState(new Array(6).fill('')); // 6-digit OTP
    const [renewVisible, setRenewVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const { confirmEmail , forgotPassword } = useAuthService()

    const handleOtpChange = (index, value) => {
        if (/^[0-9]$/.test(value) || value === '') { // Allow only digits and empty values
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Automatically focus the next input if a digit is entered
            if (value && index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }

            // Focus previous input if Backspace is pressed and current is empty
            if (value === '' && index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };

    // Handle key events to manage backspace focus
    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };

    // Handle OTP form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const otpCode = otp.join('');

        if (otpCode.length === 6 && otp.every((digit) => digit !== '')) {
            try {
                const response = await confirmEmail(otpCode, email);

                if (response.data.status === 'success') {
                    setIsLoading(false);
                    setRenewVisible(true);
                    setShowAlert(false);
                    resetOtp(); // Reset the OTP fields
                    onHide();
                } else {
                    handleFailure();
                }
            } catch (error) {
                console.error("Échec de la vérification du code OTP :", error);
                handleFailure();
            }
        } else {
            setShowAlert(true);
            setIsLoading(false);
        }
    };

    // Handle OTP resend
    const handleResendOtp = async () => {
        try {
            setShowAlert(false);
            await forgotPassword(email);
            notification.success({
                message: 'Code de vérification renvoyé',
                description: 'Le code de vérification a été renvoyé avec succès à votre email.',
                placement: 'topRight',
            });
        } catch (error) {
            notification.error({
                message: "Erreur lors de l'envoi",
                description: 'Une erreur est survenue lors de la réinitialisation du code de vérification.',
                placement: 'topRight',
            });
            console.error("Error resending OTP:", error);
        }
    };

    // Handle failure after multiple attempts
    const handleFailure = () => {
        setAttempts((prev) => prev + 1);
        setShowAlert(true);
        setIsLoading(false);
        resetOtp(); // Reset the OTP fields
        if (attempts >= 10) {
            resetOtp();
            onHide();
            notification.warning({
                message: 'Trop de tentatives',
                description: 'Vous avez atteint le nombre maximal de tentatives pour entrer le code OTP. Veuillez réessayer plus tard.',
                placement: 'topRight',
            });
        }
    };

    // Reset OTP fields
    const resetOtp = () => {
        setOtp(new Array(6).fill(''));
    };

    // Handle modal close
    const handleClose = () => {
        resetOtp();
        onHide();
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={handleClose}
                width={400}
                footer={null}
                centered
                destroyOnClose
                title={
                    <Title level={4} style={{ textAlign: 'center' }}>
                        Vérifiez votre adresse e-mail
                    </Title>
                }
            >
                {showAlert && (
                    <div style={{ marginBottom: '1rem', color: 'red' }}>
                        Le code OTP que vous avez entré est invalide. Veuillez vérifier et réessayer.
                    </div>
                )}

                <Paragraph style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    Un code de vérification à 6 chiffres a été envoyé à l'adresse e-mail suivante : 
                    <span style={{ color: '#1681FF' }}> {email}</span>. 
                    Veuillez entrer ce code pour confirmer votre adresse e-mail avant de pouvoir réinitialiser votre mot de passe.
                </Paragraph>

                <Row gutter={8} justify="center">
                    {otp.map((digit, index) => (
                        <Col key={index}>
                            <Input
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                style={{
                                    width: 48,
                                    height: 48,
                                    textAlign: 'center',
                                    fontSize: '24px',
                                }}
                            />
                        </Col>
                    ))}
                </Row>

                <Button
                    type="primary"
                    block
                    onClick={handleSubmit}
                    loading={isLoading}
                    style={{ marginTop: '24px' }}
                >
                    Confirmer
                </Button>

                <div style={{ textAlign: 'center', marginTop: '16px', fontSize: '14px' }}>
                    Vous n'avez pas reçu le code ?{' '}
                    <Button type="link" onClick={handleResendOtp}>
                        Renvoyer
                    </Button>
                </div>
            </Modal>

            <RenewPasswordModal email={email} visible={renewVisible} onHide={() => setRenewVisible(false)} />
        </>
    );
}
