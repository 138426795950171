import React, {useState, useEffect, useCallback, useRef} from "react";
import LayoutNo from "../utils/LayoutNo";
import "../style/inputChip.css";
import LocationSelector from "../utils/multiSelectEmplacement";
import { useFormationService } from "../services/formation.service";
import {Alert, Pagination, Skeleton} from "antd";
import BrushIcon from "../images/brush.svg";
import { CryptoService } from "../services/crypte.service";
import { useAuth } from "../services/auth.provider";
import FormationSearchFooter from "../components/FormationSearchFooter";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import  {  lazy, Suspense } from "react";
const FormationCard = lazy(() => import("../utils/FormationCard"));
const FormationFilters = lazy(() => import("../components/FormationFilters"));
const LoginModal = lazy(() => import("../components/modal/auth/LoginModal"));
const NodataFound = lazy(() => import("../utils/NoDataFound"));


export default function SearchSystemFormation() {
    const { getSearchPostBac,getPublicPostBac } = useFormationService();
    const [keyword, setKeyword] = useState(localStorage.getItem("keyword") || "");
    const [results, setResults] = useState([]);
    const [userId, setUserId] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPagesPublic, setTotalPagesPublic] = useState(0);
    const [totalCountPublic, setTotalCountPublic] = useState(0);
    const [currentPage, setCurrentPage] = useState(localStorage.getItem("currentPage") || 1);
    const [noResults, setNoResults] = useState(false);
    const [noPublicResults, setNoPublicResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingpub, setLoadingPub] = useState(true);
    const currentTime = Date.now();
    const didMount = useRef(false);

    const { isAuthenticated } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStudLevel, setSelectedStudLevel] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [selectedAccessRate, setSelectedAccessRate] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [checkboxValues, setCheckboxValues] = useState({ alternance: '0', enDistanciel: '0', public: '0' });
    const [filtersChanged, setFiltersChanged] = useState(false);
    const [clearFilters, setClearFilters] = useState(false);
    const [postBacData, setPostBacData] = useState([]);
    const page= process.env.REACT_APP_FORMATION_ITEM_PER_PAGE;
    const location = useLocation();
    const navigate = useNavigate();
    const attempts = parseInt(localStorage.getItem("publicApiAttempts") || "0", 10);


    const { study_level, location_s, area_study ,etabkeyword } = location.state || {};



    useEffect(() => {
        const savedSearchData = JSON.parse(localStorage.getItem("formSearchData"));
        if (savedSearchData) {
            setKeyword(etabkeyword || savedSearchData.keyword || "");
            setSelectedStudLevel(
                study_level && study_level != 0
                    ? [study_level]
                    : savedSearchData?.selectedStudLevel || []
            );

            setSelectedArea( area_study || savedSearchData.selectedArea ||  []);
            setSelectedAccessRate(savedSearchData.selectedAccessRate || []);
            setSelectedLocations(
                location_s && location_s.length > 0
                    ? [location_s]
                    : savedSearchData?.selectedLocations || []
            );
            setCheckboxValues(savedSearchData.checkboxValues || { alternance: '0', enDistanciel: '0', public: '0' });
            setCurrentPage(savedSearchData.currentPage || 1);
        }
    }, []);

    /** Effect to initialize user data and perform a search on authentication **/
    useEffect(() => {

        window.scrollTo(0, 0);
        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            const savedSearchData = JSON.parse(localStorage.getItem("formSearchData"));

            if (user) {
                const decryptedId = CryptoService.decryptData(user);
                setUserId(decryptedId);
                handleSearch();
            }
            if (savedSearchData) {
                setSelectedStudLevel(savedSearchData.selectedStudLevel || []);
                setSelectedArea(savedSearchData.selectedArea || []);
                handleSearch();

            }

        }
    }, [isAuthenticated]);








    /** Search handler function to fetch filtered results **/

    const handleSearch = useCallback(async () => {

        if(!userId) return;
        setLoading(true);
        setNoResults(false);

        const apprenticeshipValue = checkboxValues.alternance === "1" ? 1 : undefined;
        const distencielValue = checkboxValues.enDistanciel === "1" ? 1 : undefined;
        const publicValue = checkboxValues.public === "1" ? 1 : undefined;
        const studLevelValue =  (selectedStudLevel && selectedStudLevel.length > 0) ? selectedStudLevel : undefined;
        const areaValue = (selectedArea && selectedArea.length > 0) ? selectedArea : undefined;
        const accessRateValue = (selectedAccessRate && selectedAccessRate.length > 0) ? selectedAccessRate : undefined;
        const locationsValue = (selectedLocations && selectedLocations.length > 0) ? selectedLocations : undefined;

        try {
            const response = await getSearchPostBac(
                userId,
                currentPage,
                keyword,
                apprenticeshipValue,
                distencielValue,
                publicValue,
                studLevelValue ,
                areaValue,
                accessRateValue,
                locationsValue
            );

            if (response.success) {
                setResults(response.data.results);
                setTotalPages(response.data.total_pages || 0);
                setTotalCount(response.data.total_count || 0);
                setNoResults(false);
            } else {
                setResults([]);
                setNoResults(true);
            }
        } catch (error) {
            console.error("Error fetching search results:", error);
            setResults([]);
            setNoResults(true);
        } finally {
            setLoading(false);
        }
    }, [userId, currentPage, keyword, checkboxValues, selectedStudLevel, selectedArea, selectedAccessRate, selectedLocations]);


    /**  fetch public PostBac data  **/

    const handleSearchPublic = useCallback(async () => {

        setLoadingPub(true);
        setNoPublicResults(false);

        const apprenticeshipValue = checkboxValues.alternance === "1" ? 1 : undefined;
        const distencielValue = checkboxValues.enDistanciel === "1" ? 1 : undefined;
        const publicValue = checkboxValues.public === "1" ? 1 : undefined;
        const studLevelValue =  (selectedStudLevel && selectedStudLevel.length > 0) ? selectedStudLevel : undefined;
        const areaValue = (selectedArea && selectedArea.length > 0) ? selectedArea : undefined;
        const accessRateValue = (selectedAccessRate && selectedAccessRate.length > 0) ? selectedAccessRate : undefined;
        const locationsValue = (selectedLocations && selectedLocations.length > 0) ? selectedLocations : undefined;


        try {
            const response = await getSearchPostBac(419,currentPage,keyword,apprenticeshipValue,distencielValue,publicValue,studLevelValue,areaValue,accessRateValue,locationsValue);
            if (response.success) {
                setPostBacData(response.data.results);
                console.log(response.data)
                setTotalPagesPublic(response.data.total_pages || 0);
                setTotalCountPublic(response.data.total_count || 0);
                localStorage.setItem("lastAttemptTime", currentTime.toString());
                localStorage.setItem("publicApiAttempts", (attempts + 1).toString());
                setNoPublicResults(false);
            } else {
                setPostBacData([]);
                setNoPublicResults(true);
            }
        } catch (error) {
            console.error("Error fetching search results:", error);
            setPostBacData([]);
            setNoPublicResults(true);

        } finally {
            setLoadingPub(false);
        }
    }, [currentPage,keyword, checkboxValues, selectedStudLevel, selectedArea, selectedAccessRate, selectedLocations]);








    useEffect(() => {
        const userSearchData = {
            keyword,
            selectedStudLevel,
            selectedArea,
            selectedAccessRate,
            selectedLocations,
            checkboxValues,
            currentPage
        };
        if(isAuthenticated){
        localStorage.setItem("formSearchData", JSON.stringify(userSearchData));
        }
    }, [ keyword, selectedStudLevel, selectedArea, selectedAccessRate, selectedLocations, checkboxValues, currentPage]);



    const handleLocationSelect = (locations) => {
        setSelectedLocations(locations);
        setFiltersChanged(true);
        setCurrentPage(1);
        localStorage.setItem(
            "formSearchData",
            JSON.stringify({
                ...JSON.parse(localStorage.getItem("formSearchData")),
                selectedLocations: locations,
            })
        );
    };


    const handleCheckboxChange = (key) => {
        setCheckboxValues((prevState) => ({
            ...prevState,
            [key]: prevState[key] === '1' ? '0' : '1',
        }));
        setFiltersChanged(true);
        setCurrentPage(1);
    };

    const handleClear = () => {
        setKeyword("");
        setSelectedLocations([]);
        setCheckboxValues({ alternance: "0", enDistanciel: "0", public: "0" });
        setSelectedStudLevel(null);
        setSelectedArea(null);
        setSelectedAccessRate(null);
        setCurrentPage(1);
        setFiltersChanged(true);
        setClearFilters(true);
        navigate(location.pathname, {
            state: {
                ...location.state,
                study_level: null,
                location_s: [],
                area_study: null,
                etabkeyword: "",
            },
        });
        setTimeout(() => {
            setClearFilters(false);
        }, 100);
    };



    const handleStudLevelChange = (selectedOptions) => {
        setSelectedStudLevel(selectedOptions.map(option => option.studlevel_id));
        setFiltersChanged(true);
        setCurrentPage(1);
    };

    const handleAreaChange = (selectedOptions) => {
        setSelectedArea(selectedOptions.map(option => option.areastud_id));
        setFiltersChanged(true);
        setCurrentPage(1);
    };

    const handleAccessRateChange = (selectedOptions) => {
        setSelectedAccessRate(selectedOptions.map(option => option.access_rate_range_id));
        setFiltersChanged(true);
        setCurrentPage(1);
    };

    const onPageChange = (page) => {
        if(!isAuthenticated && attempts >=10){
            showModal()
        }else {
            setCurrentPage(page);
            setFiltersChanged(false);
            window.scrollTo(0, 0);
        }

    };






    useEffect(() => {
        if (userId && filtersChanged) {
            handleSearch(null);
            setFiltersChanged(false);
        }
        // }else  if (!userId && filtersChanged) {
        //     handleSearchPublic(null);
        //     setFiltersChanged(false);
        // }
    }, [userId, currentPage, filtersChanged, handleSearch]);



    useEffect(() => {
        if (userId) {
            handleSearch();
        }
    }, [userId, currentPage, filtersChanged]);


    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            handleSearchPublic();
            return;
        }

        if (!userId) {
            handleSearchPublic();
        }
    }, [currentPage, checkboxValues, selectedStudLevel, selectedArea, selectedAccessRate, selectedLocations]);


    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (isAuthenticated) {
                handleSearch();
            } else {
                if (attempts >= 10) {
                    showModal();
                } else {
                    handleSearchPublic();
                }
            }
        }
    };

    function formatTotalCount(count) {
        const countStr = count.toString();
        if (countStr.length <= 3) {
            return countStr;
        }
        return countStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }


    return(
        <Suspense fallback={<div></div>}>
            <LayoutNo>
                <Helmet>
                    <title>Linkyways</title>
                </Helmet>
                <div className="p-1">

                    <div className="  relative z-10 flex justify-center items-center px-3 md:px-2   md:mt-3 mt-14">
                        <div className="bg-white   w-full border-1 border-[#D0DBE7] shadow-md  rounded-lg md:rounded-md   flex flex-wrap md:flex-nowrap items-center    space-y-2 md:space-y-0" >

                            {/* Mots clé Input */}
                            <div className="relative flex-grow md:flex-grow-0 md:w-1/2 md:p-0 p-2   border-none border-[#D0DBE7] rounded-t-md">
                            <span className="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-500 ml-1">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.332 12.333L15.7493 15.7502" stroke="#BBBBBB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                                <input
                                    type="text"
                                    placeholder="Formation, école, université..."
                                    value={keyword}
                                    onKeyPress={handleKeyPress}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    className="w-full pl-5  text-sm font-normal   placeholder-[#BEBEBFff]   rounded-l focus:outline-none"
                                    spellCheck={false}
                                    style={{ fontSize: "16px" }}

                                />
                            </div>

                            {/* Divider for Large Screens */}
                            <div className="hidden md:block w-px bg-[#D0DBE7] h-6  "></div>

                            {/* Divider for Small Screens */}
                            <div className="block md:hidden w-full bg-[#D0DBE7] mt-0 mb-1"></div>

                            <div className="flex flex-col md:flex-row flex-grow -mt-1 md:space-y-0 border-t-2 md:border-none border-[#D0DBE7] rounded-b-md" >
                                <LocationSelector onLocationSelect={handleLocationSelect}  selectedLocations={selectedLocations}  />
                            </div>

                            {/* Recherche Button for Large Screens */}
                            <button
                                type="button"
                                onClick={() => {
                                    if (!isAuthenticated) {
                                        if (attempts >= 10) {
                                            showModal();
                                        } else {
                                            handleSearchPublic(null);
                                        }
                                    } else {
                                        handleSearch(null);
                                    }
                                }}
                                className="hidden md:flex  justify-center items-center p-2 m-1 text-[16px] font-inter font-semibold  rounded-md border border-blue-500 bg-[#1681FF] text-white"
                            >
                                {/* Search Icon */}
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                                    <path d="M8.40307 14.0625C11.7636 14.0625 14.4878 11.4182 14.4878 8.15625C14.4878 4.89432 11.7636 2.25 8.40307 2.25C5.04258 2.25 2.31836 4.89432 2.31836 8.15625C2.31836 11.4182 5.04258 14.0625 8.40307 14.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.7051 12.333L16.2256 15.7502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Recherche
                            </button>

                        </div>
                    </div>
                    {/*<div className="hidden md:flex   justify-center items-center md:my-2 " onClick={!isAuthenticated ? showModal : null}>*/}
                    <div className="hidden md:flex   justify-center items-center md:my-2 " >
                        <FormationFilters
                            checkboxValues={checkboxValues}
                            onCheckboxChange={handleCheckboxChange}
                            onSelectAccessChange={handleAccessRateChange}
                            onSelectAreaChange={handleAreaChange}
                            onSelectStudLevel={handleStudLevelChange}
                            clearFilters={clearFilters}
                            selectedStudLevelIds={selectedStudLevel}
                            selectedAreaIds={selectedArea}
                            selectedAccessRateIds={selectedAccessRate}
                        />
                    </div>
                    <div className=" md:hidden  left-0 right-0  px-3 mt-3 ">
                        <button
                            onClick={() => {
                                if (!isAuthenticated) {
                                    if (attempts >= 10) {
                                        showModal();
                                    } else {
                                        handleSearchPublic(null);
                                    }
                                } else {
                                    handleSearch(null);
                                }
                            }}
                            type="button"
                            className="flex  w-full  justify-center items-center py-2  mt-2 text-md font-medium rounded-lg border border-[#1681FF] bg-blue-500 text-white   "
                        >
                            Recherche
                        </button>
                    </div>

                    {/*</div>*/}
                    <div className="md:hidden flex mt-3 px-3" >
                        <FormationFilters
                            checkboxValues={checkboxValues}
                            onCheckboxChange={handleCheckboxChange}
                            onSelectAccessChange={handleAccessRateChange}
                            onSelectAreaChange={handleAreaChange}
                            onSelectStudLevel={handleStudLevelChange}
                            clearFilters={clearFilters}
                            selectedStudLevelIds={selectedStudLevel}
                            selectedAreaIds={selectedArea}
                            selectedAccessRateIds={selectedAccessRate}
                            handleLocationSelect={handleLocationSelect}
                            selectedLocations={selectedLocations}

                        />

                    </div>
                    <div>
                        <div className="flex justify-between md:mt-3 mt-0 lg:mt-0 mx-4 md:mx-3">
                            <p className="font-poppins font-semibold text-sm pt-2 md:pb-0 pb-0 ">
                                Formations :
                                {loading || loadingpub ? (
                                    <span className=" ml-2 animate-pulse bg-gray-300 rounded w-12 h-3 text-transparent ">34 248</span>
                                ) :  !loadingpub && !isAuthenticated ? (
                                    <span className="ml-2">{formatTotalCount(totalCountPublic)}</span>
                                ) : (
                                    <span className="ml-2">{formatTotalCount(totalCount)}</span>
                                )}
                            </p>

                            { !loading && (
                                <button
                                    onClick={loading || handleClear}

                                    className="cursor-pointer flex justify-center items-center text-xs px-2 gap-1  border-1 border-[#D0DBE7] rounded-md mb-1"
                                >
                                    <img src={BrushIcon} alt="edit" className="object-cover" />
                                    <span className="ml-1 font-inter text-[14px] text-[#141943]">Tout effacer</span>

                                </button>
                            )}
                        </div>
                        { selectedAccessRate?.length !== 0 &&(
                            <div className="mx-4 md:mx-3 mb-2">
                                <Alert message="Le taux d'accès n'est pas disponible pour toutes les formation." type="warning" showIcon />
                            </div>
                        )}
                        <div className=" px-3 md:px-2">
                            {loading &&  isAuthenticated && (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2  p-2">
                                    {Array.from({ length: 16 }).map((_, index) => (
                                        <Skeleton active key={index} />
                                    ))}
                                </div>
                            )}
                            {loadingpub && !isAuthenticated && (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2   p-2">
                                    {Array.from({ length: 16 }).map((_, index) => (
                                        <Skeleton active key={index} />
                                    ))}
                                </div>
                            )}
                            {!loading && (noResults || noPublicResults)  && (
                                <div className="mt-4">
                                    <NodataFound clearFilter={handleClear}/>
                                </div>
                            )}

                            {!loading && !noResults && results.length > 0 && (
                                <>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2  p-2">

                                        {Array.isArray(results) && results.map((item) => (
                                            <FormationCard
                                                key={item.postbac_id}
                                                title={item.nom_formation}
                                                smtitle={item.nom_etab}
                                                imgSrc={item.logo}
                                                userId={userId}
                                                chips={[
                                                    item.departement,
                                                    item.niveau,
                                                    item.apprentissage ,
                                                    item.taux_dacces,
                                                    item.source,
                                                    item.duree,
                                                    item.amenagement === "En présentiel" ? null : "Distanciel"
                                                ].filter(Boolean)}
                                                data={item}
                                                id={item.postbac_id}
                                            />
                                        ))}
                                    </div>
                                    <div className="flex justify-center mb-4 mt-4">
                                        {!loading && !noResults && (totalPages || totalPagesPublic) > 1 && (
                                            <Pagination
                                                total={totalCount}
                                                current={currentPage}
                                                pageSize={page}
                                                onChange={onPageChange}
                                                showSizeChanger={false}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                            { !loadingpub && !isAuthenticated  &&(
                                <div className="px-2 mt-2 ">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2  ">
                                        {Array.isArray(postBacData) && postBacData.map((item) => (
                                            <FormationCard
                                                key={item.postbac_id}
                                                title={item.nom_formation}
                                                smtitle={item.nom_etab}
                                                imgSrc={item.logo}
                                                userId={userId}
                                                chips={[
                                                    item.departement,
                                                    item.niveau,
                                                    item.apprentissage ,
                                                    item.taux_dacces,
                                                    item.amenagement === "En présentiel" ? null : "Distanciel"
                                                ].filter(Boolean)}
                                                data={item}
                                                id={item.postbac_id}
                                            />
                                        ))}

                                    </div>
                                    <div className="flex justify-center mt-4" >
                                        <Pagination
                                            total={totalCountPublic}
                                            current={currentPage}
                                            pageSize={page}
                                            onChange={onPageChange}
                                            showSizeChanger={false}
                                        />
                                    </div>
                                    <FormationSearchFooter/>
                                </div>

                            )}
                            <LoginModal visible={isModalVisible} onHide={hideModal}  />
                        </div>

                    </div>
                </div>
            </LayoutNo>
        </Suspense>
    )
}