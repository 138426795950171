import { Button, Card, Modal } from "antd";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import LoginModal from "../components/modal/auth/LoginModal";
import UserIcon from "../images/iconuser.svg";
import FormationSearchFooter from "../components/FormationSearchFooter";
import {Helmet} from "react-helmet";

export default function Home() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);



    return (
        <div className="overflow-hidden bg-gray-50  ">
            <Helmet>
                <title>Linkyways - M'orienter avec l'IA !</title>
                <meta name="description" content="Décourez les métiers qui sont faits pour vous, et les formations associées en quelques clics. 1 700 métiers et 35 000 formations."/>
            </Helmet>
            <div className="flex justify-center items-center mt-3 mb-2">
                <>
                    <Link to="/apropos">
                        <button className=" py-2 rounded-full transition font-poppins text-[#141943]  text-sm font-semibold ">
                            À propos
                        </button>
                    </Link>
                    <Link to="/public/inscription" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] text-sm font-semibold">
                        S'inscrire
                    </Link>
                    <Button
                        type="default"
                        className="flex items-center border border-gray-300 px-4 py-2 rounded-full transition font-poppins text-[#141943] text-sm font-semibold"
                        onClick={showModal}
                    >
                        <span>S'identifier</span>
                        <img
                            src={UserIcon}
                            alt="Login Icon"
                            className="ml-2 h-5 w-5"
                        />
                    </Button>
                </>
            </div>

        <div className="flex justify-center items-center p-2">

            <Card
                className="shadow-xl rounded-xl"
                style={{
                    width: "500px",
                    maxWidth: "800px",
                }}
                // bodyStyle={{
                //     padding: "20px",
                // }}
            >
                    <div className=" text-center px-1 sm:px-1 md:px-5 lg:px-6">

                    <div className="text-center flex flex-col items-center">
                        <img
                            src={logo}
                            alt="Linkyways Logo"
                            className="my-2 w-10 h-100 mx-2 "
                        />
                    </div>
                    <p className="text-center font-semibold text-[15px] font-poppins text-[#4E5B6B] py-2">
                        <br />
                        Plateforme d’orientation <span className="text-blue-600 font-bold ">100% gratuite.</span>
                    </p>

                    <div className="space-y-6 mt-5">
                        <div className="flex flex-col items-center justify-center">
                            <p className="text-sm font-poppins  text-[#141943] my-3">
                                Découvrez votre <span className="text-[#1273B4] font-bold">IKIGAI</span> en 10 minutes
                            </p>
                            <Link to={'/ikigai'} className="w-full">
                                <button
                                    className="w-full bg-[#FF6C22] text-white font-medium text-sm py-2 rounded-md "
                                >
                                    Passer le test d’orientation
                                </button>
                            </Link>
                        </div>

                        <div className="flex flex-col items-center justify-center">
                            <div className="text-sm font-poppins  text-[#141943] mt-3 mb-1">
                                <p>Plus de <span className="text-[#1273B4] font-bold"> 30,000 formations</span> référencées</p>
                                <p className="-mt-2">Parcoursup - Mon Master</p>
                            </div>
                            <Link to={'/formations'} className="w-full">
                                <button
                                    className="w-full bg-blue-500 text-white font-medium text-sm py-2 rounded-md"
                                >
                                    Rechercher une formation
                                </button>
                            </Link>
                        </div>

                        <div className="flex flex-col items-center justify-center mb-2 md:mb-5">
                            <p className="text-sm font-poppins  text-[#141943] my-3">
                                Définissez votre <span className="text-[#1273B4] font-bold">projet professionnel</span>
                            </p>
                            <Link to={'/metiers'} className="w-full">
                                <button
                                    className="w-full bg-blue-500 text-white font-medium text-sm py-2 rounded-md "
                                >
                                    Rechercher un métier
                                </button>
                            </Link>
                        </div>
                            <div className="flex flex-col items-center justify-center mb-2 md:mb-8">
                                <p className="text-sm font-poppins  text-[#141943] my-3">
                                <span className="text-[#1273B4] font-bold">Être contacté(e)</span> par un conseiller
                            </p>
                            <button
                                className="w-full bg-[#55920A] text-white font-medium text-sm py-2 rounded-md "
                                onClick={ showModal}
                            >
                                Planifier mon appel
                            </button>
                        </div>
                    </div>

                </div>
            </Card>


            <LoginModal visible={isModalVisible} onHide={hideModal}  />
        </div>
            <div className=" mx-2 mt-2 md:mt-96 ">
                <FormationSearchFooter/>
            </div>
        </div>
    );
}
