import HeartIcon from "../images/heart.svg";
import React, {useState} from "react";
import LocationPin from "../images/formationIcons/locationPin.svg"
import Website from "../images/formationIcons/website.svg"
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useEffect} from "react";
import {useFormationService} from "../services/formation.service";
import FilledHeartIcon from "../images/heart-filled.svg";
import {CryptoService} from "../services/crypte.service";
import {Button, Modal, notification, Skeleton} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useAuth} from "../services/auth.provider";
import {Helmet} from "react-helmet";
import {useUserService} from "../services/userforms.service";
import LayoutNo from "../utils/LayoutNo";
import ContactApprovalModal from "../components/contactApprovalModal/ContactApprovalModal";
import ContactAvatar from "../images/ContactAvatar";
import LoginModal from "../components/modal/auth/LoginModal";
import PresentationDuCours from "../components/FormationComponents/PresentationDuCours";
import FormationCard from "../utils/FormationCard";
import FormationMap from "../utils/FormationMap";
import smalllIkigai from "../images/smallikigai.png";
import messageimg from "../images/messageimg.png";


export default function FormationDetailPage(){
    let { formationId } = useParams();
    const [formation,setFormation]=useState({});
    const { getFormationById, savePostBac, deletePostBac,getPublicPostBac,getSearchPostBac } = useFormationService();
    const [loading, setLoading] = useState(true);
    const [loadingSimilar, setLoadingSimilar] = useState(false);
    const [isFavorite, setIsFavorite] = useState();
    const [userId, setUserId] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { isAuthenticated } = useAuth();
    const { getUserCalculate } = useUserService();
    const [viewResult, setViewResult] = useState(false);
    const navigate = useNavigate();
    const [showRetourButton, setShowRetourButton] = useState(false);
    const {saveUserClickData } = useUserService();
    const [items, setItems] = useState([]);
    const areaStudy = formation.area_studies ? formation.area_studies.replace(/;/g, ",") : "";
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);
    const [isModalApproved, setIsModalApproved] = useState(false);
    const userEmail = CryptoService.decryptData(localStorage.getItem('user_email'));
    const storedVisibility = localStorage.getItem('isContactButtonVisible');
    const [isContactButtonVisible, setIsContactButtonVisible] = useState(
        storedVisibility ? JSON.parse(storedVisibility) : false
    );


    useEffect(() => {
        if (window.history.length > 1) {
            setShowRetourButton(true);
        }
    }, []);

    const handleRetourClick = () => {
        navigate("/formations");
    };

    useEffect(() => {
        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            if (user) {
                const decryptedId = CryptoService.decryptData(user);
                setUserId(decryptedId);
                fetchFormation();
            }
        }
    }, [isAuthenticated]);

    const schoolName = formation.nom_etab
        ? CryptoService.nameUrlFormatVF(formation.nom_etab)
        : "unknown-school";

    const formationTitle = formation.nom_formation
        ? CryptoService.nameUrlFormatVF(formation.nom_formation)
        : "unknown-formation";

    const showContactModal = () => setIsContactModalVisible(true);
    const hideContactModal = () => setIsContactModalVisible(false);
    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const fetchFormation = async () => {
        try {
            const response = await getFormationById(formationId, userId,showModal);
            const formationData = response.data.data[0];
            // console.log(response.data.data[0]);
            setFormation(formationData);
            setIsFavorite(formationData.saved);
        } catch (err) {
            // console.log("user has to login ");
        } finally {
            setLoading(false);

        }
    };

    const getSimilarItems = async () => {
        try {
            let response;
            setLoadingSimilar(true);

            if (isAuthenticated) {
                response = await getSearchPostBac(userId,1,"","","","",formation.studlevel_id, areaStudy,"",formation.region || formation.commune, formationId,4);
            } else {
                response = await getPublicPostBac(1,"", "", "", "", formation.studlevel_id, areaStudy, "", formation.region || formation.commune, formationId, 4);
            }
            const filteredItems = response.data.results.filter(item => item.postbac_id.toString() !== formationId.toString());
            setItems(filteredItems);
        } catch (err) {
            console.error("Error fetching similar items:", err);
        } finally {
            setLoadingSimilar(false);
        }
    };

    const fetchData = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const decryptedId = CryptoService.decryptData(userId)
            const response = await getUserCalculate(decryptedId);

            if (Array.isArray(response.data) && response.data.data.length === 0) {
                setViewResult(false);
            } else {
                setViewResult(true);
            }

        } catch (error) {
            console.error("Error fetching user calculate:", error);
            setViewResult(false);
        }
    };


    useEffect(() => {
        fetchFormation();
        getSimilarItems();
        fetchData();
        window.scrollTo(0, 0);
    }, [userId,formationId,isFavorite ]);



    const handleFavoriteToggle = async () => {
        if (!isAuthenticated) {
            showModal();
            return;
        }
        try {
            if (isFavorite) {
                const response = await deletePostBac(userId, formationId);
                handleClick("unsave")
                if (response.success) {
                    setIsFavorite(false);
                }
            } else {
                const response = await savePostBac(userId, formationId);
                if (response.success) {
                    setIsFavorite(true);
                    notification.success({
                        message: 'Enregistré',
                        placement: 'topRight',
                    });
                    handleClick("save")
                }
            }
        } catch (error) {
            console.error("Error toggling favorite status:", error);
        }
    };


    const handleClick = (type) => {

        const params = {
            idFormation:formationId,
            userId,
            enregistrer:false,
            formationLabelisee: false,
            siteWeb: false,
            parcoursup: false,
        };

        switch (type) {
            case "parcourssup":
                params.parcoursup = true;
                break;
            case "siteWeb":
                params.siteWeb = true;
                break;
            case "save":
                params.enregistrer = true;
                break;
            case "unsave":
                params.enregistrer = false;
                break;
            default:
                console.error("Unknown button type");
                return;
        }

        saveUserClickData(params);
    };

    const handleNavigate = () => {
        let formSearchData = JSON.parse(localStorage.getItem("formSearchData")) || {};

        formSearchData = {
            ...formSearchData,
            keyword: "",
            selectedAccessRate: [],
            selectedLocations: [],
            checkboxValues: {
                alternance: "0",
                public: "0",
                enDistanciel: "0",
            },
        };

        localStorage.setItem("formSearchData", JSON.stringify(formSearchData));

        navigate("/formations", {
            state: {
                study_level: formation.studlevel_id,
                location_s: formation.region || formation.commune,
                area_study: areaStudy,
            },
        });
    };


    const onContactButtonHide = () => {
        setIsContactButtonVisible(false);
    };
    const handleTestButtonClick = () => {
        navigate('/public/test-orientation');
    };

    const handleResultsButtonClick = () => {
        navigate('/orientation');
    };

    if(loading){
        return (
            <LayoutNo>
                <div className="p-1">
                    <div className="animate-pulse   md:mt-2 mt-12">
                        <div className="rounded-md bg-gray-300 h-56 w-full mb-4"></div>
                    </div>
                    <div className="animate-pulse md:flex md:w-8/12 flex-col    gap-1">
                        <div className="rounded-md bg-gray-300 h-56 w-full mb-4"></div>
                        <div className="rounded-md bg-gray-300 h-56 w-full mb-4"></div>
                    </div>
                    <div className="animate-pulse  md:w-8/12  gap-1">
                        <div className="rounded-md bg-gray-300 h-96 w-full mb-4"></div>
                    </div>
                </div>
            </LayoutNo>
        );
    }


    return(
            <LayoutNo>
                {formation.nom_etab && formation.nom_formation ? (
                    <Helmet>
                        <title>{formation.title} </title>
                        <meta name="description" content={formation.meta || ""} />
                        <link rel="canonical" href={`https://linkyways.com/formation/${schoolName}/${formationTitle}/${formationId}`}/>
                    </Helmet>
                ):null}
                <div className="px-3 py-1 md:mt-0 mt-2">

                    <div className="relative mt-12 md:mt-2 w-full flex flex-col md:flex-row  ">
                        <div className="w-full bg-[#E9F2FB] border-1 md:p-4 p-2 rounded-lg ">
                            {showRetourButton && (
                                <Button
                                    icon={<LeftOutlined  className={"text-blue-500    text-lg"}/>}
                                    onClick={handleRetourClick}
                                    className="hidden md:flex absolute p-1 top-1 left-2  shadow-lg bg-white backdrop-blur-lg border-0 rounded-full  "
                                    shape="circle"
                                />
                            )}
                            <div>


                                <div className="hidden md:block md:w-8/12 ">

                                    <div className="flex justify-between">
                                        <div className="flex items-center">
                                            <img
                                                src={formation.logo}
                                                alt="formation_img"
                                                className="rounded-md mr-4 w-2 "
                                            />
                                            <div className="w-full">
                                                <p className="font-semibold text-[16px] font-poppins text-[#141943] mr-5 ">
                                                    {formation.tc}
                                                </p>
                                                <p className="font-bold text-[24px] font-inter text-[#141943] mr-5 ">
                                                    {formation.nom_formation}
                                                </p>
                                                    <p className="font-semibold text-[15px]  text-[#141943] mr-5 mb-3">
                                                        {formation.filiere}
                                                    </p>
                                                <div className="flex justify-between w-full ">
                                                    <div className="justify-start">
                                                        <p className="text-[#434769] font-inter max-w-16rem">
                                                            {formation.nom_etab}
                                                        </p>
                                                        {/*</a>*/}
                                                    </div>
                                                    <div className="flex items-center justify-end">
                                                        <img
                                                            src={Website}
                                                            alt="Poursuite d'études"
                                                            className="mb-3 mr-1"
                                                        />
                                                        <a
                                                            onClick={() => handleClick("siteWeb")}
                                                            href={formation.etab_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-gray-800 underline"
                                                        >
                                                            <p className="text-[#434769]  font-inter">
                                                                site web
                                                            </p>
                                                        </a>
                                                    </div>
                                                </div>


                                                <div className="flex  items-center">
                                                    <img
                                                        src={LocationPin}
                                                        alt="Poursuite d'études"
                                                        className="mb-3 mr-1"
                                                    />
                                                    <p className="text-[#434769] font-inter">
                                                        {formation?.code_postal?.split('.')[0] || '--'}{" "}{formation.commune || 'Unknown'}, {formation.region || 'Unknown'}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="justify-end md:mr-5 xl:mr-8">
                                            <button onClick={handleFavoriteToggle}>
                                                <img
                                                    src={isFavorite ? FilledHeartIcon : HeartIcon}
                                                    alt={isFavorite ? "Remove from favorites" : "Add to favorites"}
                                                    className="object-cover min-w-6 w-5 h-5"
                                                />
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div className="block md:hidden w-full ">
                                    <div className="flex flex-col space-y-4 mb-5" >
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center justify-start">
                                                <img
                                                    src={formation.logo}
                                                    alt="formation_img"
                                                    className="rounded-md mr-2  w-2"
                                                />
                                                <p className="font-semibold text-[16px] font-inter text-[#141943] mr-1 max-w-12rem  line-clamp-2">
                                                    {formation.nom_etab}
                                                </p>
                                            </div>
                                            <button onClick={handleFavoriteToggle} className="justify-end">
                                                <img
                                                    src={isFavorite ? FilledHeartIcon : HeartIcon}
                                                    alt={isFavorite ? "Remove from favorites" : "Add to favorites"}
                                                    className="h-7 mb-1 w-10 h-10"
                                                />
                                            </button>
                                        </div>
                                        <hr className="border-t-2 mt-2  border-gray-300 " />
                                        <div className="w-full">
                                            <p className="font-semibold text-[16px] font-poppins text-[#141943]  ">
                                                {formation.tc}
                                            </p>
                                            <p className="font-bold text-[20px] font-inter text-[#141943] ">
                                                {formation.nom_formation}
                                            </p>
                                                <p className="font-semibold text-[15px] -mt-2  text-[#141943] ">
                                                    {formation.filiere}
                                                </p>


                                            <div className="flex items-center -mt-2">
                                                <img
                                                    src={LocationPin}
                                                    alt="Poursuite d'études"
                                                    className="mr-1 mb-3 inline-block"
                                                />
                                                <p className="text-[#434769] text-[14px] font-inter">
                                                    {formation?.code_postal?.split('.')[0] || '--'}{" "}{formation.commune || 'Unknown'}, {formation.region || 'Unknown'}

                                                </p>
                                            </div>
                                            <div className="flex items-center -mt-2">
                                                <img
                                                    src={Website}
                                                    alt="Poursuite d'études"
                                                    className="mr-1 mb-3 inline-block "
                                                />

                                                <p className="text-[#434769] text-[14px] font-inter">
                                                    <a
                                                        onClick={() => handleClick("siteWeb")}
                                                        href={formation.etab_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-gray-800 underline"
                                                    >
                                                        <p className="text-[#434769] mt-2 font-inter">
                                                            site web
                                                        </p>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <PresentationDuCours
                            niveau={formation.niveau}
                            amenagement={formation.amenagement}
                            apprentissage={formation.apprentissage}
                            duree={formation.duree}
                            fiche={formation.fiche}
                            totalcandidatsformation={formation.effectif_total_des_candidats_pour_une_formation}
                            selectivite={formation.selective}
                            taux_dacces={formation.taux_dacces}
                            formationNometab={formation.nom_etab}
                            source={formation.source}
                            taux18mois={formation.taux18mois}
                            taux6mois={formation.taux6mois}
                            formationGps={formation.gps}
                            capacite={formation.capacite_de_letablissement_par_formation}
                            clickParcours={() => handleClick("parcourssup")}
                        />

                    </div>
                    <div className="md:w-8/12 md:mt-3 md:px-0 px-2">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 md:mr-4 md:ml-1 gap-2 xl:mr-8">
                            {/** First Card */}

                            <div className=" mx-auto bg-white shadow-lg rounded-md border-1 bg-white border-[#D0DBE7] w-full md:w-6/12  rounded-md  flex flex-col h-full  overflow-hidden p-2">
                                <div className="flex">
                                    <div className="md:w-8/12 w-9/12  p-1">
                                        <p className="font-poppins text-[16px] mt-2 font-semibold text-[#141943] leading-6">
                                            Votre projet professionnel pas encore défini ?
                                        </p>
                                    </div>
                                    <div className="md:w-4/12 w-3/12 ">
                                        <img src={smalllIkigai} alt="ikigai " className="object-cover w-auto h-auto " />

                                    </div>
                                </div>
                                <div className="flex flex-col flex-grow">
                                    <p className="text-gray-600 mt-2 text-sm text-justify">
                                        Découvre ton métier idéal en combinant IKIGAI et intelligence artificielle
                                    </p>
                                    <button
                                        className=" w-full p-2 bg-blue-500 rounded-md text-white font-semibold   mt-auto"
                                        onClick={viewResult ? handleResultsButtonClick : handleTestButtonClick}
                                    >
                                        {viewResult ? "Voir mes résultats" : "Passer le test"}
                                    </button>

                                </div>
                            </div>

                            <div className=" mx-auto bg-white shadow-lg rounded-md border-1 bg-white border-[#D0DBE7] w-full md:w-6/12  rounded-md  flex flex-col h-full  overflow-hidden p-2">

                                <div className="flex">
                                    <div className="md:w-8/12 w-9/12  p-1">
                                        <p className="font-poppins text-[16px] mt-2 font-semibold text-[#141943] leading-6">
                                            Besoin d’un coup de main pour choisir une formation ?
                                        </p>

                                    </div>
                                    <div className="md:w-4/12 w-3/12  ">
                                        <img src={messageimg} alt="message " className="object-cover w-auto h-auto " />
                                    </div>
                                </div>
                                <div className="flex flex-col flex-grow">
                                    <p className="text-gray-600 mt-2 text-sm text-justify">
                                        Nos conseillers d’orientation t’accompagnent dans tes recherches gratuitement
                                    </p>
                                    <button
                                        className={`w-full p-2 rounded-md font-semibold mt-auto ${
                                            isAuthenticated
                                                ? isContactButtonVisible
                                                    ? "bg-blue-500 text-white"
                                                    : "bg-gray-100 text-gray-600 cursor-not-allowed"
                                                : "bg-blue-500 text-white"
                                        }`}
                                        onClick={showContactModal}
                                        disabled={isAuthenticated && !isContactButtonVisible}
                                    >
                                        {isAuthenticated
                                            ? isContactButtonVisible
                                                ? "Planifier mon appel"
                                                : "Demande déjà enregistrée"
                                            : "Planifier mon appel"}
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="md:w-8/12  md:mx-0 mx-2  mb-2 mt-3  ">
                        <div className="flex flex-col md:flex-row justify-between   md:space-x-4 md:mr-4 xl:mr-8">
                            <div className=" rounded-md   w-full md:w-8/12   rounded-md ">
                                <p className="px-4 pt-3  font-poppins text-[16px] font-semibold text-[#141943]">
                                    Formations similaires
                                </p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2 px-2 py-2">
                                    {loadingSimilar ? (
                                        Array.from({ length: 4 }).map((_, index) => (
                                            <Skeleton active key={index} />
                                        ))
                                    ) : (
                                        Array.isArray(items) &&
                                        items.map((item) => (
                                            <FormationCard
                                                key={item.postbac_id}
                                                title={item.nom_formation}
                                                smtitle={item.nom_etab}
                                                imgSrc={item.logo}
                                                userId={userId}
                                                chips={[
                                                    item.departement,
                                                    item.niveau,
                                                    item.apprentissage,
                                                    item.taux_dacces,
                                                    item.amenagement === "En présentiel" ? null : "Distanciel",
                                                ].filter(Boolean)}
                                                data={item}
                                                id={item.postbac_id}
                                            />
                                        ))
                                    )}
                                </div>

                                <div className="flex justify-end md:mb-5">
                                    <Button
                                        onClick={isAuthenticated ? handleNavigate : showModal}
                                        // onClick={ handleNavigate}
                                        className={`font-semibold text-md text-white gap-3 p-3 mt-3 font-semibold ${
                                            loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500"
                                        }`}
                                        disabled={loading}
                                    >
                                        Voir plus
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>

                    {formation.gps !== "0" &&(
                        <div className="  md:w-8/12 mt-3 md:mx-0 mx-2 ">
                            <div className="flex md:hidden flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 md:mr-4 xl:mr-8">
                                <div className=" border-1   border-[#D0DBE7]  w-full md:w-8/12 shadow-md rounded-md ">
                                    <FormationMap gps={formation.gps} nomEtab={formation.nom_etab}/>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="  md:w-8/12 mt-3 md:mx-0 mx-2 mb-32">
                        <div className="flex md:hidden flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 md:mr-4 xl:mr-8">
                            <div className=" border-1   border-[#D0DBE7]  w-full md:w-8/12 shadow-md rounded-md ">
                                <div className=" p-2  w-full  ">
                                    <p className="font-poppins text-[16px] font-semibold text-[#141943]">
                                        Débouchés professionnels
                                    </p>
                                    <div className="items-center mt-2">
                                        <Link to={'/metiers'} className="w-full">
                                            <button
                                                className="w-full bg-blue-500 text-white font-medium text-sm py-2 rounded-md "
                                            >
                                                Rechercher un métier
                                            </button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoginModal visible={isModalVisible} onHide={hideModal} />
                <Modal
                    centered
                    className="border-1 border-green-500 rounded-lg "
                    open={isContactModalVisible}
                    onCancel={hideContactModal}
                    footer={null}
                    closeIcon={null}
                >
                    <div className="flex items-center justify-center  ">
                        <div className="w-full max-w-4xl  flex">
                            {/* Left Column */}
                            <div className="w-7/12 flex flex-col justify-center">
                                {/* Text */}
                                <p className="text-xl font-semibold text-gray-800">
                                    Souhaitez-vous être contacté(e) par un conseiller ?
                                </p>
                                {/* Buttons */}
                                <div className="mt-1 flex space-x-4">
                                    <button onClick={hideContactModal} className="md:px-6 px-3 md:py-2 py-1 border border-green-500 text-green-500 rounded-lg hover:bg-green-100">
                                        Non
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (isAuthenticated) {
                                                setIsModalApproved(true);
                                                hideContactModal();
                                            } else {
                                                showModal();
                                            }
                                        }}

                                        className="md:px-6 px-3 md:py-2 py-1 bg-green-500 text-white rounded-lg hover:bg-green-600">
                                        Oui
                                    </button>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div className="w-5/12 md:mr-0 mr-2 flex items-center justify-center">
                                <div className="  flex items-center justify-center">
                                    <ContactAvatar/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ContactApprovalModal
                    userId={userId}
                    userEmail={userEmail}
                    isVisible={isModalApproved}
                    onClose={() => setIsModalApproved(false)}
                    onContactButtonHide={onContactButtonHide}
                />

            </LayoutNo>


    )
}