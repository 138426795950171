import React, {useEffect, useState} from 'react';
import { Select, message, Form,Card} from "antd";
import {Link} from "react-router-dom"
import {useUserService} from "../../services/userforms.service";
import {EyeInvisibleOutlined, EyeOutlined, GoogleOutlined} from '@ant-design/icons';
import {useAuthService} from "../../services/auth.service";
import { Input, Button, Tooltip, Typography, Alert } from 'antd';
import {VerificationModal} from "../modal/auth/VerificationModal";
import ConditionsGeneralesDialog from "../../utils/ConditionsGenerales";
import {LocalStorageService} from "../../services/localStorage.service";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../services/auth.provider";
import {useGoogleLogin} from "@react-oauth/google";
import {CryptoService} from "../../services/crypte.service";
import {useSubmissionService} from "../../services/submission.service";
import GoogleIcon from "../../images/GoogleIcon";
import logo from "../../images/logo.svg";


const UserModal = ({isVisible, onClose}) => {
    const {Text } = Typography;
    const [currentStep, setCurrentStep] = useState(0);
    const [username, setUsername] = useState('');
    const { signUp } = useAuth();
    const [form] = Form.useForm();
    const [userStatusOptions, setUserStatusOptions] = useState([]);
    const [studiesLevelOptions, setStudiesLevelOptions] = useState([]);
    const [bacTypeOptions, setBacTypeOptions] = useState([]);
    const [areaStudy, setAreaStudy] = useState([]);
    const [selectedUserStatus, setSelectedUserStatus] = useState(null);
    const [selectedStudyLevel, setSelectedStudyLevel] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [emptyValue, setEmptyValue] = useState(false);
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
    const { checkEmail } = useAuthService();
    const {getUserStatus, getStudiesLevel, getBacType, getBacSpeGeneral, get17AreaStudies, getBacSpePro, getBacSpeTechno} = useUserService();
    const [responseData, setResponseData] = useState([]);
    const { userSubmission } = useSubmissionService();
    const [selectedBacType, setSelectedBacType] = useState(null);
    const [selectedSpecializations, setSelectedSpecializations] = useState([]);
    const [selectedAreaStudies, setSelectedAreaStudies] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const navigate = useNavigate();
    const [passwordValidation, setPasswordValidation] = useState({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
        hasSpecialChar: false
    })


    let maxTagCount = 3;
    if (selectedBacType === null) {
        maxTagCount = 3;
    } else if (selectedStudyLevel === 2 && selectedBacType === 1) {
        maxTagCount = 3;
    } else if (selectedStudyLevel === 3 && selectedBacType === 1) {
        maxTagCount = 2;
    } else if (selectedStudyLevel === 3 && (selectedBacType === 2 || selectedBacType === 3)) {
        maxTagCount = 1;
    }



    const validatePassword = (password) => {
        const validation = {
            minLength: password.length >= 8,
            hasUppercase: /[A-Z]/.test(password),
            hasLowercase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[@$!%*?&]/.test(password),
        };
        setPasswordValidation(validation);
        return validation;
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        const validation = validatePassword(value);
        const isValid = Object.values(validation).every(Boolean);
        setShowTooltip(!isValid);
    };

    const getValidationMessage = () => (
        <div>
            <Text type={passwordValidation.minLength ? 'success' : 'danger'}>Au moins 8 caractères</Text><br />
            <Text type={passwordValidation.hasUppercase ? 'success' : 'danger'}>Une majuscule</Text><br />
            <Text type={passwordValidation.hasLowercase ? 'success' : 'danger'}>Une minuscule</Text><br />
            <Text type={passwordValidation.hasNumber ? 'success' : 'danger'}>Un chiffre</Text><br />
            <Text type={passwordValidation.hasSpecialChar ? 'success' : 'danger'}>Un caractère spécial (@$!%*?&)</Text>
        </div>
    );

    const fetchUserStatus = async () => {
        try {
            const response = await getUserStatus();
            if (response.data.status === "success") {
                const options = response.data.data.map((item) => ({
                    id: item.status_id,
                    value: item.status_name,
                    label: item.status_name,
                }));
                setUserStatusOptions(options);
            } else {
                console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
            console.log(err.message || "An error occurred");
        }
    };

    const fetchStudiesLevel = async (index, graduate) => {
        try {
            const response = await getStudiesLevel(index, graduate);
            if (response.data.status === "success") {
                const options = response.data.data.map((item) => ({
                    id: item.studlevel_id,
                    value: item.study_level,
                    label: item.study_level,
                }));
                setStudiesLevelOptions(options);
            } else {
                console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
            console.log(err.message || "An error occurred");
        } 
        // finally {
        //     setLoading(false);
        // }
    };

    const fetchBacType = async () => {
        try {
            const response = await getBacType();
            if (response.data.status === "success") {
                const options = response.data.data.map((item) => ({
                    id: item.bac_type_id,
                    value: item.bac_type,
                }));
                setBacTypeOptions(options);
            } else {
                 console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
             console.log(err.message || "An error occurred");
        }
    };

    const fetchAreaStudy = async () => {
        try {
            const response = await get17AreaStudies();
            if (response.data.status === "success") {
                const options = response.data.data.map((item) => ({
                    id: item.areastud_id,
                    value: item.area_studies,
                }));
                setAreaStudy(options);
            } else {
                 console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
             console.log(err.message || "An error occurred");
        }
    };

    const handleUserStatusChange = (value, option) => {
        setSelectedUserStatus(option.id);
        setSelectedStudyLevel(null);
        setSelectedBacType(null);
        setSelectedSpecializations([]);
        setSelectedAreaStudies([]);
        setStudiesLevelOptions([]);
        setBacTypeOptions([]);
        setResponseData([]);
        setAreaStudy([]);

        if (option.id === 1 || option.id === 2) {
            fetchStudiesLevel(option.id, null);
        } else {
            fetchStudiesLevel(null, 1);
        }
    };

    const handleStudyLevelChange = (value, option) => {
        setSelectedStudyLevel(option.id);
        //console.log("stud lev  ", option.id)
        setSelectedBacType(null);
        setSelectedSpecializations([]);
        setSelectedAreaStudies([]);
        setBacTypeOptions([]);
        setResponseData([]);
        setAreaStudy([]);

        if (option.id === 2 || option.id === 3) {
            fetchBacType();
        }
        else {
            fetchAreaStudy();
        }
    };

    const handleBacTypeChange = (value) => {
        setSelectedBacType(value);
        setSelectedAreaStudies([])
        setSelectedSpecializations([])


        if (value === 1) {
            fetchBacSpeGeneral();
        } else if (value === 2) {
            fetchBacSpeTechno();
        } else if (value === 3) {
            fetchBacSpePro();
        }
    };

    const fetchBacSpeTechno = async () => {
        try {
            const response = await getBacSpeTechno();
            if (response.data.status === "success") {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.bac_spe_techno_id,
                    value: item.spe_name,
                }));
                setResponseData(fetchedTypes);
            } else {
                 console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
             console.log(err.message || "An error occurred");
        } 

    };

    const fetchBacSpePro = async () => {
        try {
            const response = await getBacSpePro();
            if (response.data.status === "success") {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.bac_spe_pro_id,
                    value: item.spe_name,
                }));
                setResponseData(fetchedTypes);
            } else {
                 console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
             console.log(err.message || "An error occurred");
        } 

    };

    const fetchBacSpeGeneral = async () => {
        try {
            const response = await getBacSpeGeneral();
            if (response.data.status === "success") {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.bac_spe_general_id,
                    value: item.spe_name,
                }));
                setResponseData(fetchedTypes);
            } else {
                 console.log(response.data.message || "An error occurred");
            }
        } catch (err) {
             console.log(err.message || "An error occurred");
        } 

    };

    useEffect(() => {
            fetchUserStatus();
    }, []);

    const handleNext = async () => {
        try {
            if (LocalStorageService.isLoggedIn()) {
                const email = LocalStorageService.getEmail();
                const sub = LocalStorageService.getSub();

                try {
                    const response = await signUp(email,null,sub,username,selectedUserStatus,selectedStudyLevel,selectedBacType,selectedSpecializations,selectedAreaStudies)

                        if (response?.data?.status === 'success') {
                        navigate("/");
                    } else {
                        console.error("Error during sign-up");
                    }
                } catch (error) {
                    console.error("Error during sign-up:", error);
                }
            } else {
                await form.validateFields();
                setCurrentStep(currentStep + 1);
            }
        } catch (info) {
            console.error("Validation failed:", info);
        }
    };



    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = async () => {
        setEmptyValue(false);
        setAlreadyExist(false);

        if (!email || !password) {
            setEmptyValue(true);
            return;
        }

        const isValidPassword = Object.values(validatePassword(password)).every(Boolean);
        if (!isValidPassword) {
            message.error("Le mot de passe doit comporter au moins 8 caractères, une majuscule, une minuscule, un chiffre, et un caractère spécial.");
            return;
        }

        try {
            let lastEmail = localStorage.getItem('email-signup');
            let response;

            if (lastEmail) {
                response = await checkEmail(email, lastEmail, 1);
            } else {
                response = await checkEmail(email, null, 0);
            }

            if (response.data.status === 'success') {
                setCurrentStep(3);
            } else {
                setAlreadyExist(true);
            }
        } catch (error) {
            setAlreadyExist(true);
            console.error('Signup failed:', error);
        }
    };

    const isSubmitDisabled = !Object.values(passwordValidation).every(Boolean) || !email ;

    const googleLoginHandler = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                    },
                });

                const userInfo = await userInfoResponse.json();

                const email = userInfo.email;
                const sub = userInfo.sub;
                const signUpResponse = await signUp(email, null,sub,username,selectedUserStatus,selectedStudyLevel,selectedBacType,selectedSpecializations,selectedAreaStudies)
                if (signUpResponse.data.status === 'success') {

                    const questionsScoresData = JSON.parse(localStorage.getItem('questionsScores')) || {};
                    const subjectsLevelData = JSON.parse(localStorage.getItem('ratings')) || {};

                    setTimeout(() => {
                        if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
                            navigate("/orientation");
                        } else {
                            navigate("/formations");
                        }
                    }, 2000);

                    if (Object.keys(questionsScoresData).length > 0 && Object.keys(subjectsLevelData).length > 0) {
                        const userId = localStorage.getItem('user_id');
                        if (userId) {
                            const decryptedId = CryptoService.decryptData(userId)
                            userSubmission(decryptedId);
                            localStorage.removeItem('questionsScores');
                            localStorage.removeItem('ratings');
                        } else {
                            console.warn("No user ID found in local storage");
                        }
                    }
                } else {
                    console.error("Error during sign-up");
                }

            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: () => {
            console.error('Google login failed');
        },
        scope: 'openid email profile',
    });


    return (
        <>
            <Card
                className="border-2 rounded-xl "
                style={{
                    maxWidth: 500,
                    width: "90%",
                }}
                bodyStyle={{
                    padding: "20px",
                }}
            >
                {currentStep === 3 ? (
                        <p className="text-center font-poppins font-bold text-[#141943] text-4xl px-3">
                            Confirmez votre adresse e-mail
                        </p>
                ) :(
                    <div className="text-center flex flex-col items-center">
                        <Link to="/" className="text-center flex flex-col items-center">
                        <img
                            src={logo}
                            alt="Linkyways Logo"
                            className="mb-5 md:w-[300px] w-[200px] p-3 mx-2 "
                        />
                        </Link>
                    </div>
                )}



            {currentStep === 0 && (
                <div className="px-3">
                <div >
                    <label htmlFor="user-status" style={{ display: "block"}} className="mb-1">
                        Prénom
                    </label>
                    <Input placeholder="Prénom" style={{width: "100%", marginBottom: 10}}   value={username}
                           onChange={(e) => setUsername(e.target.value)}/>
                </div>
                    <div className="mt-[5px]">
                        <label htmlFor="user-status" style={{ display: "block"}} className="mb-1">
                            Statut
                        </label>
                        <Select
                            style={{width: "100%", marginBottom: 10}}
                            placeholder="Statut"
                            options={userStatusOptions}
                            value={userStatusOptions.find((opt) => opt.id === selectedUserStatus) || null}
                            onChange={handleUserStatusChange}
                            labelInValue
                        />
                    </div>


                    {selectedUserStatus !== null && selectedUserStatus !== 5 && (
                        <div className="mt-[5px]">
                            <label htmlFor="user-stuydy-level" style={{ display: "block"}} className="mb-1">
                                {selectedUserStatus === 3 || selectedUserStatus === 4
                                    ? "Dernier niveau d’études validé"
                                    : "Niveau d’études "}

                            </label>
                        <Select
                            style={{width: "100%", marginBottom: 16}}
                            // placeholder="Niveau d’études"
                            placeholder={
                                selectedUserStatus === 3 || selectedUserStatus === 4
                                    ? "Dernier niveau d’études validé"
                                    : "Niveau d’études "
                            }
                            options={studiesLevelOptions}
                            onChange={handleStudyLevelChange}
                            value={studiesLevelOptions.find((opt) => opt.id === selectedStudyLevel) || null}

                            labelInValue
                        />
                        </div>
                    )}

                    {selectedStudyLevel !== 1 && selectedStudyLevel !=null && selectedUserStatus === 1 && (
                        <div className="mt-[5px]">
                            <label htmlFor="user-bac-type" style={{ display: "block"}} className="mb-1">
                                 Filière
                            </label>
                        <Select
                            style={{width: "100%", marginBottom: 16}}
                            placeholder="Filière"
                            value={bacTypeOptions.find((opt) => opt.id === selectedBacType) || null}

                            options={bacTypeOptions.map((opt) => ({
                                label: opt.value,
                                value: opt.id,
                            }))}
                            onChange={handleBacTypeChange}
                        />
                        </div>
                    )}

                    { selectedUserStatus !== 5  && selectedStudyLevel !==1 && (selectedBacType !== null || selectedStudyLevel !== null )&& (


                        <div >
                            <label htmlFor="user-status" style={{ display: "block"}} className="mb-1">
                                Spécialités
                            </label>
                            <Select
                                style={{ width: "100%" }}
                                placeholder={"Spécialités"}
                                showSearch={false}
                                disabled={!selectedBacType && areaStudy.length === 0}
                                options={
                                    selectedStudyLevel === 2 || selectedStudyLevel === 3
                                        ? responseData.map((opt) => ({
                                            label: opt.value,
                                            value: opt.id,
                                        }))
                                        : areaStudy.map((opt) => ({
                                            label: opt.value,
                                            value: opt.id,
                                        }))
                                }
                                mode={
                                    selectedBacType === null ||
                                    (selectedStudyLevel === 2 && selectedBacType === 1) ||
                                    (selectedStudyLevel === 3 && selectedBacType === 1)
                                        ? "multiple"
                                        : undefined
                                }
                                maxTagCount={maxTagCount}
                                value={selectedStudyLevel === 2 || selectedStudyLevel === 3 ? selectedSpecializations : selectedAreaStudies}
                                onChange={(value) => {
                                    const isSpecialization = selectedStudyLevel === 2 || selectedStudyLevel === 3;

                                    if (isSpecialization) {
                                        if (selectedSpecializations.length > maxTagCount) {
                                            message.info(`Sélectionnez jusqu'à ${maxTagCount} spécialités.`);
                                            return;
                                        }
                                        if (selectedStudyLevel === 2 && selectedBacType === 1 && value.length > 3) {
                                            message.info("Sélectionnez jusqu'à 3 spécialités.");
                                            return;
                                        }
                                        if (selectedStudyLevel === 3 && selectedBacType === 1 && value.length > 2) {
                                            message.info("Sélectionnez jusqu'à 2 spécialités.");
                                            return;
                                        }
                                        if (selectedStudyLevel === 3 && (selectedBacType === 2 || selectedBacType === 3) && value.length > 1) {
                                            message.info("Sélectionnez une seule spécialité.");
                                            return;
                                        }
                                        if (selectedBacType === null && value.length > 3) {
                                            message.info("Sélectionnez jusqu'à 3 spécialités.");
                                            return;
                                        }
                                        setSelectedSpecializations(value);
                                    } else {
                                        if (value.length > 3) {
                                            message.info("Sélectionnez jusqu'à 3 spécialités.");
                                            return;
                                        }
                                        setSelectedAreaStudies(value);
                                    }

                            }}
                        />
                        </div>
                    )}
                </div>)}

            {currentStep === 1 && (
                <div className="flex flex-col items-center mx-3 gap-2">
                    <Button
                        icon={<GoogleIcon />}
                        block
                        size="large"
                        type="primary"
                        className="mb-2"
                        onClick={() => googleLoginHandler()}

                    >
                        S'inscrire avec Gmail
                    </Button>
                    <Button
                        block
                        size="large"
                        onClick={handleNext}
                    >
                        S'inscrire avec Email
                    </Button>
                </div>
            )}
            {currentStep === 2 && (
                <>
                    <div className="flex justify-center items-center mt-2 mx-3 ">
                        <div className="max-w-md w-full bg-white ">
                            <div>
                                {emptyValue && (
                                    <Alert
                                        message="Veuillez remplir tous les champs"
                                        type="error"
                                        showIcon
                                        className="mb-2"
                                    />
                                )}

                                {alreadyExist && (
                                    <Alert
                                        message="L'adresse e-mail que vous avez saisie existe déjà. Veuillez en utiliser une autre."
                                        type="error"
                                        showIcon
                                        className="mb-2"
                                    />
                                )}

                                <div className="flex flex-col mb-3">
                                    <Text className="block mb-1" style={{ fontSize: '12px', color: '#141943E5', fontFamily: 'Inter' }}>
                                        E-mail
                                    </Text>
                                    <Input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="E-mail"
                                        className="bg-[#F2F2F2]"
                                    />
                                </div>

                                <div className="flex flex-col">
                                    <Text className="block mb-1" style={{ fontSize: '12px', color: '#141943E5', fontFamily: 'Inter' }}>
                                        Mot de passe
                                    </Text>
                                    <Tooltip
                                        title={getValidationMessage()}
                                        placement="bottom"
                                        visible={showTooltip}
                                        overlayStyle={{ maxWidth: 200 }}
                                    >
                                        <Input.Password
                                            value={password}
                                            onChange={handlePasswordChange}
                                            placeholder="********"
                                            iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                            className="bg-[#F2F2F2]"
                                            style={{ paddingRight: '1rem' }}
                                            onFocus={() => setShowTooltip(true)}
                                            onBlur={() => setShowTooltip(!Object.values(passwordValidation).every(Boolean))}
                                        />
                                    </Tooltip>
                                </div>


                            </div>
                        </div>
                    </div>
                </>
            )}
            {currentStep === 3 && (
                <>
                    <VerificationModal
                        email={email}
                        password={password}
                        selectedUserName={username}
                        selectedUserStatus={selectedUserStatus}
                        selectedStudyLevel={selectedStudyLevel}
                        selectedBacType={selectedBacType}
                        selectedSpecializations={selectedSpecializations}
                        selectedAreaStudies={selectedAreaStudies}
                        handlePrevious={handlePrevious}
                    />
                </>
            )}


            <div style={{marginTop: 24, textAlign: "center"}} className="px-3" >
                {currentStep < 1 ? (
                    <Button
                        type="primary"
                        className="w-full"
                        onClick={handleNext}
                        disabled={
                            username.length ===0 ||
                            selectedUserStatus === null ||
                            (selectedUserStatus !== 5 && selectedStudyLevel === null) ||
                            (selectedUserStatus !== 5 &&
                                selectedStudyLevel !== 1 &&
                                selectedSpecializations.length === 0 &&
                                selectedAreaStudies.length === 0
                            ) ||
                            (selectedStudyLevel === 3 && (selectedBacType === 2 || selectedBacType === 3) && selectedSpecializations.length === 0) ||
                            (selectedStudyLevel === 3 && selectedBacType === 1 && selectedSpecializations.length > 2) ||
                            (selectedStudyLevel === 2 && selectedBacType === 1 && selectedSpecializations.length > 3) ||
                            (selectedSpecializations.length > 0 && selectedSpecializations.length < maxTagCount)

                        }
                    >
                        Continuer
                    </Button>

                ) : (currentStep === 0 || currentStep === 2)  ? (
                    <Button
                        type="primary"
                        className="w-full"
                        onClick={handleFinish}
                        disabled={isSubmitDisabled}
                    >
                        S'inscrire
                    </Button>
                ) : null}
                {(currentStep === 1 || currentStep === 2)  && (
                    <Button style={{marginRight: 8}} className="w-full mt-2 " onClick={handlePrevious}>
                        Retour
                    </Button>
                )}
                {(LocalStorageService.isLoggedIn() && currentStep === 1) || currentStep === 1 &&(
                    <p className="text-xs text-gray-500 m-2">
                        En cliquant sur "Continuer", vous acceptez les{' '}
                        <button
                            onClick={() => setOpenConditionsDialog(true)}
                            className="text-blue-500 cursor-pointer underline bg-transparent border-none p-0"
                            type="button"
                        >
                            CGU
                        </button>{' '}
                        de Linkyways.
                    </p>
                )}
        </div>
            <ConditionsGeneralesDialog
                open={openConditionsDialog}
                onClose={() => setOpenConditionsDialog(false)}
            />
            </Card>
        </>
    )
}
export default UserModal;

