import React, { useState } from 'react';
import { Modal, Button, Input, Steps, Divider, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, GoogleOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate ,Link } from 'react-router-dom';
import ConditionsGeneralesDialog from "../../../utils/ConditionsGenerales";
import { encryptWithPublicKey } from '../../../services/rsa.service';
import { useAuth } from "../../../services/auth.provider";
import { ResetPasswordModal } from "./ResetPasswordModal";
import {LocalStorageService} from "../../../services/localStorage.service";
import {CryptoService} from "../../../services/crypte.service";
import GoogleIcon from "../../../images/GoogleIcon";
import logo from "../../../images/logo.svg";

export function LoginModal({ visible, onHide, toRoute, onLoginSuccess }) {
    const [currentStep, setCurrentStep] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
    const navigate = useNavigate();
    const { login, checkEmailGoogle } = useAuth();

    const handleGoogleLogin =  useGoogleLogin({
        onSuccess: async (response) => {
            try {
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                    },
                });

                const userInfo = await userInfoResponse.json();
                console.log(userInfo);

                const email = userInfo.email;
                console.log('User email:', email);


                let responseEmail = await checkEmailGoogle(email, userInfo.sub);
                console.log("response ", responseEmail);

                if (responseEmail.data.status === "success") {
                    let userResponseInfo = responseEmail.data.data;
                    console.log("userInfo ", userResponseInfo);

                    LocalStorageService.saveAccessToken(userResponseInfo.access_token)
                    LocalStorageService.saveRefreshToken(userResponseInfo.refresh_token)
                    localStorage.setItem('user_email', CryptoService.encryptData(userResponseInfo.user_email.toString()));
                    localStorage.setItem('user_id', CryptoService.encryptData(userResponseInfo.user_id.toString()));
                    if (userResponseInfo.user_name && userResponseInfo.user_surname) {
                        localStorage.setItem('user_name', CryptoService.encryptData(userResponseInfo.user_name.toString()));
                        localStorage.setItem('user_surname', CryptoService.encryptData(userResponseInfo.user_surname.toString()));
                        navigate("/formations");
                    } else {
                        LocalStorageService.saveSubGoogle(userInfo.sub.toString());
                        navigate("/public/inscription");
                    }
                    handleClose();
                } else {
                    console.error('User email check was not successful:', responseEmail.data.message);
                }

            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: () => {
            console.error('Google login failed');
        },
        scope: 'openid email profile',
    });

    const handleNext = () => {
        if (currentStep === 1 && (!email || !password)) {
            notification.error({ message: 'Veuillez remplir tous les champs' });
            return;
        }
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => setCurrentStep(currentStep - 1);

    const handleLogin = async () => {
        if (!email || !password) {
            notification.error({ message: 'Veuillez remplir tous les champs' });
            return;
        }

        setIsLoading(true);
        try {
            const encryptedPassword = encryptWithPublicKey(password);
            const response = await login(email, encryptedPassword);

            if (response.status === 'success') {
                //notification.success({ message: 'Bienvenue à Linkyways' });
                onLoginSuccess?.();
                navigate("/formations");
                handleClose();
            } else {
                notification.error({ message: 'Identifiants incorrects' });
            }
        } catch (error) {
            notification.error({ message: 'Une erreur est survenue. Veuillez réessayer.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
        if (token && email && password) {
            handleLogin();
        }
    };

    const handleClose = () => {
        setEmail('');
        setPassword('');
        setCaptchaToken(null);
        setCurrentStep(0);
        onHide();
    };

    const steps = [
        {
            title: 'Choisir une option',
            content: (
                <div className="flex flex-col items-center gap-2">
                    <Button
                        icon={<GoogleIcon />}
                        block
                        size="large"
                        type="primary"
                        className="mb-2 font-poppins font-medium"
                        onClick={handleGoogleLogin}
                    >
                        Continuer avec Gmail
                    </Button>
                    <Button
                        block
                        size="large"
                        className="border-blue-500 font-poppins font-medium text-blue-500"
                        onClick={handleNext}
                    >
                        Continuer avec Email
                    </Button>
                    {/*<hr className="font-bold  w-full border-2  my-5" />*/}
                    <Divider className="text-sm my-5">Pas encore de compte ?</Divider>
                    <Link to={'/public/inscription'}>
                    <Button
                        block
                        size="large"
                        className="border-blue-500 text-blue-500 font-poppins font-medium"
                        onClick={handleNext}
                    >
                        S'inscrire gratuitement
                    </Button>
                    </Link>

                </div>
            ),
        },
        {
            title: 'Entrer vos infos',
            content: (
                <div>
                    <div className="mb-3">
                        <label htmlFor="email" className="block text-sm font-medium mb-1">
                            E-mail
                        </label>
                        <Input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="you@linkyways.com"
                            size="large"
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="block text-sm font-medium mb-1">
                            Mot de passe
                        </label>
                        <Input.Password
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="********"
                            size="large"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                        <div className="text-right mt-2">
              <span
                  className="text-sm text-blue-500 underline cursor-pointer"
                  onClick={() => setResetPasswordVisible(true)}
              >
                Mot de passe oublié?
              </span>
                        </div>
                    </div>
                    <Button block size="large" type="primary" onClick={handleNext}>
                        Continuer
                    </Button>
                </div>
            ),
        },
        {
            title: 'Validation',
            content: (
                <div className="flex justify-center">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleCaptchaChange}
                        className="mb-4"
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <Modal
                title={
                <>
                    <div className="text-center flex flex-col items-center">
                        <Link to="/" className="text-center flex flex-col items-center">
                            <img
                                src={logo}
                                alt="Linkyways Logo"
                                className="mb-5 md:w-[300px] w-[200px] p-3 mx-2 "
                            />
                        </Link>
                    </div>
                    {/*<h2 className="text-2xl text-[#141943] text-center font-bold font-poppins mb-5">S’authentifier</h2>*/}
                </>

            }
                visible={visible}
                onCancel={handleClose}
                footer={null}
                centered
                width={450}
                maskStyle={{ backgroundColor: 'rgb(255,255,255)' }}
                // maskTransitionName="fade"
            >
                <div>{steps[currentStep].content}</div>
                {currentStep > 0 && (
                    <Button className="mt-2" onClick={handlePrev} block>
                        Retour
                    </Button>
                )}
                {/*{currentStep === 1 && (*/}
                {/*    <p className="text-xs text-gray-500 m-2">*/}
                {/*        En cliquant sur "Se connecter" ou "Continuer", vous acceptez les{' '}*/}
                {/*        <button*/}
                {/*            onClick={() => setOpenConditionsDialog(true)}*/}
                {/*            className="text-blue-500 cursor-pointer underline bg-transparent border-none p-0"*/}
                {/*            type="button"*/}
                {/*        >*/}
                {/*            CGU*/}
                {/*        </button>{' '}*/}
                {/*        de Linkyways.*/}
                {/*    </p>*/}
                {/*)}*/}

            </Modal>

            <ResetPasswordModal visible={resetPasswordVisible} onHide={() => setResetPasswordVisible(false)} />
            <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />
        </>
    );
}

export default LoginModal;

