import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Registration from "./components/Registration";
import Guidance from "./pages/Guidance";
import Testing from './pages/Testing';
import SujetsPreferes from './pages/SujetsPreferes';
import SearchSystemFormation from "./pages/SearchSystemFormation";
import SavedItems from "./pages/SavedItems";
import MetierDetailPage from "./pages/MetierDetailPage";
import ErrorPage from './pages/ErrorPage';
import { ProgressProvider } from './context/ProgressContext';
import SearchSystemmetier from "./pages/SearchSystemMetier";
import QuiCeQueIkigai from "./pages/QuiCeQueIkigai";
import PrivateRoute from './utils/routes/PrivateRoute';
import { AuthProvider } from './services/auth.provider';
import Cgu from "./pages/cgu/CguPage";
import MentionsL from "./pages/cgu/MentionsL";
import FormationDetailPage from "./pages/FormationDetailPage";
import IsAccessibleRoute from './utils/routes/IsAccessibleRoute';
import PublicRoute from "./utils/routes/PublicRoute";
import CookieConsent from "./components/cookie/Cookie";
import StaticSearch from "./SeoStaticSearchEnginPages/searchPages/StaticSearch";
import Apropos from "./pages/Apropos";
import Home from "./pages/Home";
import AproposPage from "./pages/AproposPage";
import ParcourSupPage from "./pages/ParcourSupPage";
import Maintenance from "./pages/Maintenance";
import ParcoursSup from "./pages/ParcoursSup";
import Chatbot from "./pages/ChatBot";

function App() {

  return (
      // <Maintenance/>
    <AuthProvider>
      <ProgressProvider>
          {/*<GoogleTagManagerHead />*/}
          <CookieConsent />
          <Routes>
            <Route path="/public/test-orientation" element={<Testing />} />
            <Route path="/conditions_generales" element={<Cgu />} />
            <Route path="/mentions_legales" element={<MentionsL />} />
            <Route path="/public/inscription" element={<Registration />} />
            <Route path="/metiers" element={<SearchSystemmetier />} />
            <Route path="/metiers/:professionName/:professionId" element={<MetierDetailPage />} />
            <Route path="/formation/:schoolName/:formationName/:formationId" element={<FormationDetailPage />} />
            <Route path="/ikigai" element={<QuiCeQueIkigai />} />
            <Route path="/faq" element={<ParcoursSup />} />
            <Route path="/apropos" element={<Apropos />} />
            <Route path="/tout-savoir-sur-parcoursup" element={<AproposPage />} />
            <Route path="/sup" element={<ParcourSupPage />} />
            <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<PublicRoute>< Home/></PublicRoute>} />
            <Route index path="/formations" element={< SearchSystemFormation/>} />

            <Route path="/public/test-orientation/sujets" element={
                  <IsAccessibleRoute>
                      < SujetsPreferes />
                  </IsAccessibleRoute>
            } />

            <Route path="/orientation"
              element={
                <PrivateRoute>
                  <Guidance />
                </PrivateRoute>
              }
            />

          <Route path="/enregistrements/:userId"
            element={
              <PrivateRoute>
                <SavedItems />
              </PrivateRoute>
            }
          />

            <Route path="/formations/bts-commerce-alternance" element={<PublicRoute><StaticSearch keyword={"commerce"} studyLevel={7} alternance={1} pathName={"Bts-commerce-alternance"} title={"BTS Commerce en Alternance : Formation et Débouchés"} metaDescription={"Découvrez le BTS Commerce en alternance, une formation pratique pour intégrer rapidement le monde du travail."}/></PublicRoute>}/>
            <Route path="/formations/bts-finance-comptabilite" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={5}  pathName={"Bts-finance-comptabilite"} title={"BTS Finance et Comptabilité : Devenez Expert en Gestion Financière"} metaDescription={"Préparez votre avenir avec un BTS Finance et Comptabilité. Une formation complète pour une carrière réussie en finance."}/></PublicRoute>}/>
            <Route path="/formations/bts-finance-comptabilite-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={5} alternance={1} pathName={"Bts-finance-comptabilite-alternance"} title={"BTS Finance et Comptabilité en Alternance : Apprenez en Entreprise"} metaDescription={"Optez pour le BTS Finance et Comptabilité en alternance et associez théorie et expérience professionnelle."}/></PublicRoute>}/>
            <Route path="/formations/bts-droit-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={6} alternance={1} pathName={"Bts-droit-alternance"} title={"BTS Droit en Alternance : Études et Opportunités Professionnelles"} metaDescription={"Découvrez le BTS Droit en alternance pour acquérir des compétences juridiques tout en travaillant."}/></PublicRoute>}/>
            <Route path="/formations/bts-droit" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={6} pathName={"Bts-droit"} title={"BTS Droit : Études Juridiques et Carrière"} metaDescription={"Obtenez un BTS Droit et accédez aux métiers du juridique grâce à une formation professionnalisante."}/></PublicRoute>} />
            <Route path="/formations/bts-art" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={4} pathName={"Bts-art"}  title={"BTS Art : Développez Votre Talent et Votre Créativité"} metaDescription={"Inscrivez-vous en BTS Art et explorez les métiers de la création, du design et des arts appliqués."}/></PublicRoute>}/>
            <Route path="/formations/bts-agriculture-environnement" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={9} pathName={"Bts-agriculture-environnement"} title={"BTS Agriculture et Environnement : Une Formation pour un Avenir Durable"} metaDescription={"Formez-vous aux métiers de l’agriculture et du développement durable avec un BTS Agriculture et Environnement."}/></PublicRoute>}/>
            <Route path="/formations/bts-agriculture-environnement-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={9} alternance={1} pathName={"Bts-agriculture-environnement-alternance"} title={"BTS Agriculture et Environnement en Alternance : Apprenez sur le Terrain"} metaDescription={"Associez théorie et expérience professionnelle avec le BTS Agriculture et Environnement en alternance."}/></PublicRoute>}/>
            <Route path="/formations/bts-social" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={7}  keyword={"social"} pathName={"Bts-social"} title={"BTS Social : Études et Débouchés dans le Secteur Social"} metaDescription={"Découvrez le BTS Social et préparez-vous à travailler dans le domaine de l’accompagnement et de l’action sociale."}/></PublicRoute>}/>
            <Route path="/formations/bts-social-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={7}  keyword={"social"} alternance={1} pathName={"Bts-social-alternance"} title={"BTS Social en Alternance : Une Formation Professionnalisante"} metaDescription={"Étudiez tout en travaillant avec le BTS Social en alternance et développez vos compétences sur le terrain."}/></PublicRoute>}/>
            <Route path="/formations/bts-sante" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={10} pathName={"Bts-sante"} title={"BTS Santé : Formez-vous aux Métiers du Secteur Médical"} metaDescription={"Suivez un BTS Santé pour accéder aux professions médicales et paramédicales rapidement."}/></PublicRoute>}/>
            <Route path="/formations/bts-sante-alternance" element={<PublicRoute><StaticSearch studyLevel={7} areaStudy={10}   alternance={1} pathName={"Bts-sante-alternance"} title={"BTS Santé en Alternance : Une Formation sur le Terrain"} metaDescription={"Associez études et expérience professionnelle avec le BTS Santé en alternance."}/></PublicRoute>}/>


            <Route path="/formations/licence-but-comptabilite" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={5}  keyword={"comptabilite"} pathName={"Licences-but-comptabilite"} title={"Licence et BUT Comptabilité : Formez-vous en Gestion Financière"} metaDescription={"Découvrez les meilleures formations en comptabilité et préparez-vous à une carrière dans la finance."}/></PublicRoute>}/>
            <Route path="/formations/Licence-but-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={5} alternance={1} keyword={"comptabilite"} pathName={"Licences-but-alternance"} title={"Licence et BUT Comptabilité en Alternance : Études et Expérience"} metaDescription={"Associez apprentissage académique et expérience professionnelle avec une Licence ou un BUT Comptabilité en alternance."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-informatique" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={1} pathName={"Licences-but-informatique"} title={"Licence et BUT Informatique : Études et Carrières Numériques"} metaDescription={"Devenez un expert en informatique avec une Licence ou un BUT Informatique."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-informatique-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={1} alternance={1} pathName={"Licences-but-informatique-alternance"} title={"Licence et BUT Informatique en Alternance : Apprentissage et Expérience"} metaDescription={"Alliez études et pratique avec une Licence ou un BUT Informatique en alternance."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-droit-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={6} alternance={1}  pathName={"Licences-but-droit-alternance"} title={"Licence et BUT Droit en Alternance : Formez-vous au Métier de Juriste"} metaDescription={"Suivez une Licence ou un BUT Droit en alternance et gagnez en expérience professionnelle."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-droit" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={6} pathName={"Licences-but-droit"} title={"Licence et BUT Droit : Études Juridiques et Débouchés"} metaDescription={"Découvrez les opportunités offertes par une Licence ou un BUT Droit."} /></PublicRoute>}/>
            <Route path="/formations/licence-but-art" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={4} keyword={"art"} pathName={"Licences-but-art"} title={"Licence et BUT Art : Explorez le Monde de la Création"} metaDescription={"Inscrivez-vous en Licence ou en BUT Art et développez vos compétences artistiques et créatives."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-design" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={4} keyword={"design"} pathName={"Licences-but-design"} title={"Licence et BUT Design : Une Formation pour les Créateurs de Demain"} metaDescription={"Devenez designer avec une Licence ou un BUT Design et apprenez les bases du graphisme et du design industriel."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-environnement" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={9} keyword={"environnement"} pathName={"Licences-but-environnement"} title={"Licence et BUT Environnement : Étudiez l’Écologie et le Développement Durable"} metaDescription={"Engagez-vous pour la planète avec une Licence ou un BUT Environnement."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-environnement-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={9} alternance={1} keyword={"environnement"} pathName={"Licences-but-environnement-alternance"} title={"Licence et BUT Environnement en Alternance : Apprenez sur le Terrain"} metaDescription={"Associez théorie et pratique avec une Licence ou un BUT Environnement en alternance."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-sport" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={7}  keyword={"sport"} pathName={"Licences-but-sport"} title={"Licence et BUT Sport : Devenez un Professionnel du Sport"} metaDescription={"Étudiez les sciences du sport avec une Licence ou un BUT et accédez aux métiers du coaching et de la gestion sportive."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-sante" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={10} pathName={"Licences-but-sante"} title={"Licence et BUT Santé : Une Formation aux Professions Médicales"} metaDescription={"Préparez-vous aux métiers de la santé avec une Licence ou un BUT Santé."}/></PublicRoute>}/>
            <Route path="/formations/licence-but-sante-alternance" element={<PublicRoute><StaticSearch studyLevel={8} areaStudy={10}   alternance={1} pathName={"Licences-but-sante-alternance"} title={"Licence et BUT Santé en Alternance : Une Formation Pratique"} metaDescription={"Alliez formation théorique et expérience en entreprise avec une Licence ou un BUT Santé en alternance."}/></PublicRoute>}/>


            <Route path="/formations/dn-made" element={<PublicRoute><StaticSearch  keyword={"dn made"} pathName={"Dn-made"} title={"DN MADE : Études en Design et Métiers d'Art"} metaDescription={"Découvrez le DN MADE et développez vos compétences en design, graphisme et métiers d’art."}/></PublicRoute>}/>


            <Route path="/formations/infirmier" element={<PublicRoute><StaticSearch  alternance={1} keyword={"infirmier"} pathName={"Infirmier"}  title={"Devenez Infirmier : Trouvez Votre Formation en Alternance"} metaDescription={"Découvrez les meilleures formations en alternance pour devenir infirmier. Comparez les écoles et trouvez le programme adapté à votre projet professionnel."} /></PublicRoute>}/>
            <Route path="/formations/licence-economie" element={<PublicRoute><StaticSearch keyword={"economie"} studyLevel={8} pathName={"Licence-economie"} title={"Licence en Économie : Formations et Débouchés"} metaDescription={"Trouvez la meilleure Licence en Économie et explorez les débouchés professionnels. Comparez les formations et lancez votre carrière économique."} /></PublicRoute>}/>
            <Route path="/formations/bts-commerce" element={<PublicRoute><StaticSearch keyword={"commerce"} studyLevel={7} pathName={"Bts-commerce"} title={"BTS Commerce : Formations en 2 Ans pour Réussir"} metaDescription={"Obtenez votre BTS Commerce et accédez rapidement au marché du travail. Découvrez les formations adaptées à vos ambitions professionnelles."}/>/></PublicRoute>}/>



          </Routes>
        {/*<GoogleTagManagerNoscript />*/}
      </ProgressProvider>
    </AuthProvider>
  );
}

export default App;
